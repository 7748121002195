<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkOppositionFormModal" centered size="xl" no-close-on-backdrop ref="trademarkOppositionFormModal" :title="$t('trademarkOpposition.trademark_opposition_info')" hide-footer @close="clear()">
        <agent-note-form ref="agentNoteFormModal"></agent-note-form>
        <client-form ref="clientFormModal"></client-form>
        <reminder-form ref="reminderFormModal"></reminder-form>
        <p-d-f-viewer ref="bulletinInfoModal"></p-d-f-viewer>
        <client-invoice-report-list-call-form ref="clientInvoiceReportListCallForm"></client-invoice-report-list-call-form>
        <div class="custom-search d-flex justify-content mb-1">
            <b-row style="margin-right: auto;">
                <b-media no-body>
                    <b-media-aside>
                        <b-img :src="image" style="height:auto;" class="uploading-image" />
                    </b-media-aside>
                    <b-media-body>
                        <b-dropdown style="width: 4em; height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                            <template #button-content>
                                <feather-icon icon="DownloadIcon" size="10" class="align-middle" />
                            </template>
                            <b-form-file ref="refInputEl" accept=".jpg, .png, .gif, .jpeg" :hidden="true" plain @change="uploadImage" />
                            <b-dropdown-item @click="$refs.refInputEl.$el.click()" size="sm">
                                <span> {{$t('others.select_image')}}</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="downloadLogo" size="sm">
                                <span> {{$t('others.download')}}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </b-media-body>
                </b-media>
            </b-row>
            <b-row style="margin-left: auto;">
                <b-col>
                    <b-button variant="outline-primary" size="sm" class="ml-05" @click.prevent="addOrUpdate">
                        {{ $t('others.ok_title') }}
                    </b-button>
                    <b-dropdown v-if="id > 0" class="ml-05" style="height: 2.1em;" size="sm" :text="$t('others.invoices')" variant="outline-primary">
                        <b-dropdown-item @click="getClientInvoiceReport(2)">{{$t('others.InvoicesToBePaid')}}: {{ invoicesToBePaid }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(3)">{{$t('others.AllInvoices')}}: {{ allInvoices }}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(4)">{{$t('others.InvoicesToBePaidForThisJob')}}: {{invoicesToBePaidForThisJob}}</b-dropdown-item>
                        <b-dropdown-item @click="getClientInvoiceReport(1)">{{$t('others.AllInvoicesRelatedToThisJob')}}: {{ allInvoicesRelatedToThisJob }}</b-dropdown-item>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="PrinterIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="downloadTrademarkOppositionWordFile(wordTemplate.id, wordTemplate.name)" v-for="wordTemplate in wordTemplateList" :key="wordTemplate.id">
                                <span>{{ wordTemplate.name }}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-dropdown style="height: 2em;" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper ml-05" size="sm">
                        <template #button-content>
                            <feather-icon icon="SendIcon" size="10" class="align-middle" />
                        </template>
                        <div class="dropdown-scroll">
                            <b-dropdown-item @click="prepareTrademarkOppositionMail(mailTemplate.id, mailTemplate.explanation)" v-for="mailTemplate in mailTemplateList" :key="mailTemplate.id">
                                <span>{{mailTemplate.explanation}}</span>
                            </b-dropdown-item>
                        </div>
                    </b-dropdown>
                    <b-button variant="outline-primary" class="ml-05" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                        <feather-icon icon="BellIcon" size="10" class="align-middle" />
                    </b-button>
                </b-col>
            </b-row>
        </div>
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="trademark-opposition-record-no-input">{{
                                $t('trademarkOpposition.trademark_opposition_folder_no') }}</label>
                        <b-form-group>
                            <b-form-input id="trademark-opposition-record-no-input" size="sm" v-model="trademarkOppositionRefCode" disabled />
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <b-form>
                        <label for="trademark-opposition-application-no-input">{{ $t('trademarkOpposition.application_no')}}</label>
                        <validation-provider #default="{ errors }" :name="$t('trademarkOpposition.application_no')" rules="required">
                            <validation-provider #default="{ errors }" :name="$t('trademarkOpposition.application_no')" rules="required">
                                <b-input-group>
                                    <b-form-input id="trademark-opposition-application-no-input" size="sm" v-model="applicationNo" maxlength="50" :state="errors.length > 0 ? false : null" />

                                    <b-input-group-append>
                                        <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="searchByApplicationNo">
                                            +
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-md-3">
                    <b-form>
                        <label for="trademark-opposition-name-input">{{ $t('trademarkOpposition.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name="$t('trademarkOpposition.name')" rules="required">
                                <b-form-input id="trademark-opposition-name-input" size="sm" v-model="name" maxlength="50" :state="errors.length > 0 ? false : null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <b-form>
                        <label for="trademark-opposition-bulletin-no-input">{{ $t('trademarkOpposition.bulletin_no')}}</label>
                        <b-input-group>
                            <b-form-input id="trademark-opposition-bulletin-no-input" size="sm" v-model="bulletinNo" maxlength="50" />
                            <b-input-group-append>
                                <b-button v-if="bulletinPage > 0" v-b-tooltip.html :title="$t('others.bulletin')" variant="warning" size="sm" @click="openBulletin()">
                                    <feather-icon icon="BoldIcon" />
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <b-form>
                        <label for="trademark-opposition-employee-input">{{ $t('trademarkOpposition.employee') }}</label>
                        <b-form-select id="trademark-opposition-employee-input" value-field="id" text-field="name" :disabled="this.employeeCheck" size="sm" v-model="employeeSelect" :options="employeeOption" />
                    </b-form>
                </b-col>
            </b-row>
            <!-- Deadline Date ,  Date ,  Application No Application Date -->
            <b-row>
                <b-col class="col-md-3">
                    <!-- TODO Disable the CustomDatePicker to user entry -->
                     <!-- DisabledCheck isnt worked in here -->
                    <label for="trademark-opposition-date-input">{{ $t('trademarkOpposition.opposition_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="2" :disabled="disabledCheck" v-model="oppositionDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2">
                    <b-form-group>
                        <label for="trademark-opposition-application-date-input">{{ $t('trademarkOpposition.application_date') }}</label>
                        <CustomDatePicker :datePickerValidateOption="0" v-model="applicationDate"></CustomDatePicker>
                    </b-form-group>
                </b-col>
                <b-col class="col-md-3">
                    <label for="trademark-opposition-deadline-input">{{ $t('trademarkOpposition.deadline') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="deadlineDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2">
                    <label for="trademark-opposition-bulletin-date-input">{{ $t('trademarkOpposition.bulletin_date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="bulletinDate"></CustomDatePicker>
                </b-col>
                <b-col class="col-md-2">
                    <!-- STOCK-1372 -->
                    <b-form-group>
                        <label for="trademark-opposition-status-input">{{ $t('trademarkOpposition.status')  }}</label>
                        <b-form-select v-model="trademarkOppositionStatusSelect" size="sm" :options="trademarkOppositionStatusOption" :disabled=true value-field="id" name="trademark-opposition-status-input" text-field="name" />
                    </b-form-group>
                    <!-- CHANGELOG : Disabled Added,TrademarkOppositionStatus change with Job records. NEVZAT 27.01.2025 -->
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-6">
                    <b-form>
                        <label for="trademark-opposition-holder-name-input">{{ $t('trademarkOpposition.holder_name') }}</label>
                        <b-form-input id="trademark-opposition-bulletin-no-input" size="sm" v-model="holderName" maxlength="150" />
                    </b-form>
                </b-col>
                <b-col class="col-md-2">
                    <b-form>
                        <label for="trademark-opposition-holder-code-input">{{ $t('trademarkOpposition.holder_code') }}</label>
                        <b-form-input id="trademark-opposition-bulletin-no-input" size="sm" maxlength="6" type="tel" v-model="holderCode" />
                    </b-form>
                </b-col>
                <b-col class="col-md-4">
                    <b-form>
                        <label for="trademark-opposition-attorney-name-input">{{ $t('trademarkOpposition.attorney_name') }}</label>
                        <b-form-input id="trademark-opposition-bulletin-no-input" size="sm" v-model="attorneyName" maxlength="150" />
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-md-12 mt-2">
                    <h4 style="text-align: center; place-content: center;">{{ $t('trademarkOpposition.nice_class') }}</h4>
                    <b-form-group>
                        <b-form-input size="sm" disabled :value="niceClassSelectString" maxlength="250" />
                        <b-form-checkbox-group id="trademark-opposition-nice-class-type-input" v-model="niceClassSelect" :options="sortedArray" value-field="name" text-field="name" class="demo-inline-spacing" />
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <b-form-group>
                        <label for="trademark-opposition-agent-input"> {{ $t('trademarkOpposition.agent') }} </label>
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="trademark-opposition-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="trademark-opposition-agent-input" v-model="trademarkOppositionAgentSelect" :options="trademarkOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{ this.$t('others.add_client') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getAgentNoteInfo(trademarkOppositionAgentSelect.clientAccountingId)">
                                        <span>{{ this.$t('others.show_note') }}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(trademarkOppositionAgentSelect.clientId)">
                                        <span>{{ this.$t('others.show_agent') }}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <!--<b-row v-else-if="hiddenControl == true">
                <b-col class="col-lg-8 mt-1">
                    <b-form-group>
                        <label for="trademark-opposition-agent-input"> {{ $t('trademarkOpposition.agent') }} </label>
                        <b-input-group class="d-flex align-items-center">
                            <b-form-group label-for="trademark-opposition-agent-input" class="flex-grow-1 mb-0">
                                <v-select id="trademark-opposition-agent-input" v-model="trademarkOppositionAgentSelect" :options="trademarkOppositionAgentOption" @input="setClientId" label="clientName" @search="onSearch" class="w-100"></v-select>
                            </b-form-group>
                            <b-input-group-append>
                                <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                    <template #button-content>
                                        <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                    </template>
                                    <b-dropdown-item @click="getClientInfo(0)">
                                        <span>{{this.$t('others.add_client')}}</span>
                                    </b-dropdown-item>
                                    <b-dropdown-item @click="getClientInfo(trademarkOppositionAgentSelect.clientId)">
                                        <span>{{this.$t('others.show_agent')}}</span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col class="col-lg-4 mt-1">
                    <b-form-group>
                        <label for="trademark-opposition-our-ref-input">{{ $t('trademarkOpposition.our_ref')}}</label>
                        <b-input-group>
                            <b-form-input id="trademark-opposition-our-ref-input" size="sm" v-model="ourRef" maxlength="11" />
                            <b-input-group-append>
                                <b-button variant="outline-primary" size="sm" class="mr-1" @click.prevent="searchByOurRef">
                                    +
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>-->
            <b-row>
                <b-col class="col-lg-12 mt-1">
                    <b-form>
                        <b-form-group :label="$t('trademarkOpposition.invoice_client_accounting')" label-for="trademarkOppositionInvoiceClientAccounting">
                            <b-input-group class="d-flex align-items-center">
                                <b-form-group class="flex-grow-1 mb-0">
                                    <v-select id="trademarkOppositionInvoiceClientAccounting" v-model="trademarkOppositionInvoiceClientSelect" :options="trademarkOppositionInvoiceClientOption" @input="setClientIdWithInvoiceClientCheck" label="clientName" @search="onInvoiceClientAccountingSearch">
                                    </v-select>
                                </b-form-group>
                                <b-input-group-append>
                                    <b-dropdown style="width: 3em; height: 2.5em" id="dropdown-grouped" variant="outline-primary" right class="dropdown-icon-wrapper" size="sm">
                                        <template #button-content>
                                            <feather-icon icon="MenuIcon" size="10" class="align-middle" />
                                        </template>
                                        <b-dropdown-item @click="getClientInfo(0)">
                                            <span>{{ this.$t('others.add_client') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getAgentNoteInfo(trademarkOppositionInvoiceClientSelect.clientAccountingId)">
                                            <span>{{ this.$t('others.show_note') }}</span>
                                        </b-dropdown-item>
                                        <b-dropdown-item @click="getClientInfo(trademarkOppositionInvoiceClientSelect.clientId)">
                                            <span>{{ this.$t('others.show_agent') }}</span>
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="col-lg-12">
                    <b-form>
                        <label for="trademark-opposition-agent-ref-code-input">{{
                                $t('trademarkOpposition.trademark_opposition_agent_ref_code') }}</label>
                        <b-form-group>
                            <b-form-input size="sm" id="trademark-opposition-agent-ref-code-input" v-model="agentReferenceNo" maxlength="50" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
        <b-card :hidden="hiddenControl">
            <b-row>
                <b-col class="mt-2">
                    <b-tabs pills vertical nav-wrapper-class="nav-vertical">
                        <b-tab active :title="$t('trademarkOpposition.owner')" style="width:100%;">
                            <b-card-text>
                                <trademark-opposition-owner-list :getClientInfo="getClientInfo" :getTrademarkOppositionList="getTrademarkOppositionList" :trademarkOppositionId="this.id"></trademark-opposition-owner-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('trademarkOpposition.digital_archive')" style="width:100%;">
                            <b-card-text>
                                <documents-list :recordId="this.id" :moduleId="6"></documents-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('trademarkOpposition.responsible')" style="width:100%;">
                            <b-card-text>
                                <trademark-opposition-responsible-list :getTrademarkOppositionList="getTrademarkOppositionList" :trademarkOppositionId="this.id" :clientId="this.clientId"></trademark-opposition-responsible-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title="$t('trademarkOpposition.basis_for_opposition')" style="width:100%;">
                            <b-card-text>
                                <trademark-opposition-basis-for-opposition-list :getTrademarkOppositionList="getTrademarkOppositionList" :trademarkOppositionId="this.id" :clientAccountingId="this.trademarkOppositionAgentSelect.clientAccountingId"></trademark-opposition-basis-for-opposition-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.job')" style="width:100%;">
                            <b-card-text>
                                <job-list :recordId="this.id" :moduleId="6" :recordNo="this.trademarkOppositionRefCode"></job-list>
                            </b-card-text>
                        </b-tab>
                        <b-tab :title=" $t('others.dailywork')" style="width:100%;">
                            <b-card-text>
                                <dailywork-list :recordId="this.id" :moduleId="6"></dailywork-list>
                            </b-card-text>
                        </b-tab>
                    </b-tabs>
                </b-col>
            </b-row>
        </b-card>
    </b-modal>
</div>
</template>

<script>
export default {
    computed: {
        niceClassSelectString() {
            return this.niceClassSelect.sort().join(' ');
        },
        sortedArray() {
            return this.niceClassOption.sort((a, b) => a.id - b.id);
        }
    },
    props: {
        trademarkOppositionId: Number,
        getTrademarkOppositionList: {
            type: Function
        },
        generalData: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            id: 0,
            clientId: 0,
            trademarkOppositionRefCode: '',
            name: '',
            applicationNo: '',
            bulletinNo: '',
            bulletinPage: 0,
            oppositionDate: null,
            bulletinDate: null,
            deadlineDate: null,
            applicationDate: null,
            trademarkOppositionAgentSelect: 0,
            trademarkOppositionAgentOption: [],
            trademarkOppositionInvoiceClientOption: [],
            trademarkOppositionInvoiceClientSelect: 0,
            agentReferenceNo: '',
            hiddenControl: true,
            wordTemplateList: [],
            image: null,
            niceClassOption: [],
            niceClassSelect: [],
            tpmkTrademark: '',
            tpmkTrademarkHolderList: '',
            holderCode: 0,
            holderName: '',
            attorneyName: '',
            employeeSelect: 0,
            employeeOption: [],
            mailTemplateList: [],
            ourRef: '',
            trademarkOppositionStatusSelect: 0,
            trademarkOppositionStatusOption: [],
            invoicesToBePaidForThisJob: 0,
            invoicesToBePaid: 0,
            allInvoices: 0,
            allInvoicesRelatedToThisJob: 0,
            clientAccountingId: 0,
            employeeCheck: false,
            disabledCheck : true,
        }
    },
    methods: {
        getClientInvoiceReport(type) {
            this.$refs.clientInvoiceReportListCallForm.showInfo(type, this.clientAccountingId, 6, this.id)
        },
        uploadImage(event) {
            var input = event.target;
            if (this.id != 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.trademarkOppositionId,
                            base64: base64
                        }
                        const controller = {
                            name: 'TrademarkOpposition',
                            actionName: 'SaveTrademarkOppositionLogo'
                        };
                        const payload = {
                            data: data,
                            controller: controller
                        };
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.getTrademarkOppositionList()
                                this.$PleaseClickSave()
                            }
                        })
                    }
                    reader.readAsDataURL(input.files[0]);
                }
            } else if (this.id == 0) {
                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.onload = (e) => {
                        this.image = e.target.result;
                    };
                    reader.readAsDataURL(input.files[0]);
                }
            }
        },
        downloadLogo() {
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkOpposition',
                actionName: 'DownloadTrademarkOppositionLogo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    const link = document.createElement('a')
                    link.href = 'data:image/jpeg;base64,' + response.data.base64
                    link.download = this.id + '.jpg'
                    link.click()
                    this.getTrademarkOppositionList()
                    this.$SaveAlert()
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        onInvoiceClientAccountingSearch(invoiceClientAccountingSearch, loading) {
            if (invoiceClientAccountingSearch.length) {
                loading(true);
                this.invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, this);
            }
        },
        async openBulletin() {
            this.$refs.bulletinInfoModal.showInfo(this.bulletinNo.toString(), this.bulletinPage, 3)
        },
        invoiceClientAccountingSearch(loading, invoiceClientAccountingSearch, vm) {
            if (invoiceClientAccountingSearch.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: invoiceClientAccountingSearch
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkOppositionInvoiceClientOption = response.data.clientList
                    loading(false)
                })
            }
        },
        async getAgentNoteInfo(clientAccountingId) {
            this.$refs.agentNoteFormModal.getNoteListByAccountingId(clientAccountingId)
        },
        async getClientInfo(clientId) {
            this.$refs.clientFormModal.showInfo(clientId)
        },
        async getReminderInfo() {
            this.$refs.reminderFormModal.showInfo(0, this.id, 2)
        },
        setClientId(event) {
            this.clientId = event.clientId
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.trademarkOppositionAgentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        searchByApplicationNo() {
            const data = {
                string: this.applicationNo
            }
            const controller = {
                name: 'Trademark',
                actionName: 'GetTpmkTrademarkByApplicationNo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                this.name = response.data.tpmkTrademark.name
                this.applicationDate = response.data.tpmkTrademark.applicationDate
                this.applicationNo = response.data.tpmkTrademark.applicationNo
                this.attorneyName = response.data.tpmkTrademark.attorney
                this.bulletinNo = response.data.tpmkTrademark.bulletinNo
                this.niceClassSelect = response.data.tpmkTrademark.niceClasses.split(' ')
                this.niceClass = response.data.tpmkTrademark.niceClasses
                this.deadlineDate = response.data.trademarkOppositionDeadlineDate
                this.bulletinDate = response.data.bulletinDate
                if (response.data.tpmkTrademarkHolderList[0] != null) {
                    this.holderName = response.data.tpmkTrademarkHolderList[0].name
                    this.holderCode = response.data.tpmkTrademarkHolderList[0].tpeOwnerCode
                }
                var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.logo)
                this.image = nonBase64Image

            })
        },
        searchByOurRef() {
            const data = {
                string: this.ourRef
            }
            const controller = {
                name: 'Trademark',
                actionName: 'GetTrademarkInfoForOppositionByRecordNo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.resultStatus == true) {
                    // The Values Down below is returned by service If Needed.
                    //   response.data.trademarkInfoForOpposition.trademarkId 
                    //   response.data.trademarkInfoForOpposition.recordNo 
                    //   response.data.trademarkInfoForOpposition.trademarkAccountingId 
                    //   response.data.trademarkInfoForOpposition.trademarkInvoiceClientAccountingId
                    // 07.04.2024 Added Trademark Information by TrademarkRecordNo (Our Ref) Nevzat STOCK-828
                    if (response.data.trademarkInfoForOpposition.trademarkAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkInfoForOpposition.trademarkAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkOppositionAgentOption = clientAccountingResponse.data.clientList
                            this.trademarkOppositionAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.trademarkOppositionAgentOption = []
                        this.trademarkOppositionAgentSelect = 0;
                    }
                    if (response.data.trademarkInfoForOpposition.trademarkInvoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkInfoForOpposition.trademarkInvoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkOppositionInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.trademarkOppositionInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.trademarkOppositionInvoiceClientOption = []
                        this.trademarkOppositionInvoiceClientSelect = 0;
                    }
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        clear() {
            this.niceClassSelect = []
            this.image = null
            this.applicationDate = null
            this.applicationNo = ''
            this.bulletinNo = ''
            this.agentReferenceNo = ''
            this.deadlineDate = null
            this.date = null
            this.attorneyName = ''
            this.employeeSelect = 0
            this.name = ''
            this.trademarkOppositionRefCode = ''
            this.oppositionDate = null
            this.bulletinDate = null
            this.holderName = ''
            this.holderCode = 0
            this.trademarkOppositionAgentSelect = 0
            this.employeeCheck = false
        },
        showInfo(id) {
            this.$refs['trademarkOppositionFormModal'].show()
            this.id = id
            if (this.id == 0)
                this.hiddenControl = true
            else
                this.hiddenControl = false
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.applicationDate = response.data.trademarkOpposition.applicationDate
                    this.applicationNo = response.data.trademarkOpposition.applicationNo
                    this.bulletinNo = response.data.trademarkOpposition.bulletinNo
                    this.bulletinPage = response.data.trademarkOpposition.bulletinPage
                    this.agentReferenceNo = response.data.trademarkOpposition.agentReferenceNo
                    this.deadlineDate = response.data.trademarkOpposition.deadline
                    this.date = response.data.trademarkOpposition.date
                    this.attorneyName = response.data.trademarkOpposition.attorneyName
                    this.trademarkOppositionRefCode = response.data.trademarkOpposition.recordNo
                    this.name = response.data.trademarkOpposition.name
                    this.oppositionDate = response.data.trademarkOpposition.date
                    this.mailTemplateList = response.data.mailTemplateList
                    this.wordTemplateList = response.data.wordTemplateList
                    this.trademarkOppositionStatusSelect = response.data.trademarkOpposition.trademarkOppositionStatusId
                    this.trademarkOppositionStatusOption = response.data.trademarkOppositionStatusList
                    //OppositionDate olarak date alanı var karışmasın , bir de record date var bu otomatik kayıt tarihi db de. 
                    this.bulletinDate = response.data.trademarkOpposition.bulletinDate
                    var nonBase64Image = 'data:image/jpeg;base64,'.concat(response.data.trademarkOpposition.logo)
                    this.image = nonBase64Image
                    this.niceClassOption = response.data.niceClassList
                    //STOCK-1372
                    if (response.data.trademarkOpposition.employeeId > 0) {
                        this.employeeSelect = response.data.trademarkOpposition.employeeId
                        this.employeeCheck = true
                    } else {
                        this.employeeSelect = localStorage.getItem('EmployeeId')
                    }
                    this.employeeOption = response.data.employeeList
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    //
                    if (response.data.trademarkOpposition.niceClass !== null) {
                        this.niceClass = response.data.trademarkOpposition.niceClass
                        this.niceClassSelect = response.data.trademarkOpposition.niceClass.split(' ')
                    } else {
                        this.niceClassSelect = []
                        this.niceClass = null
                    }
                    this.holderCode = response.data.trademarkOpposition.holderCode
                    this.holderName = response.data.trademarkOpposition.holderName

                    if (response.data.trademarkOpposition.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkOpposition.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkOppositionAgentOption = clientAccountingResponse.data.clientList
                            this.trademarkOppositionAgentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.trademarkOppositionAgentOption = []
                        this.trademarkOppositionAgentSelect = 0;
                    }
                    if (response.data.trademarkOpposition.invoiceClientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.trademarkOpposition.invoiceClientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.trademarkOppositionInvoiceClientOption = clientAccountingResponse.data.clientList
                            this.trademarkOppositionInvoiceClientSelect = clientAccountingResponse.data.clientList[0]
                        })
                    } else {
                        this.trademarkOppositionInvoiceClientOption = []
                        this.trademarkOppositionInvoiceClientSelect = 0;
                    }
                    if (this.id > 0) {
                        let accountingId = 0
                        if (response.data.trademarkOpposition.clientAccountingId > 0)
                            accountingId = response.data.trademarkOpposition.clientAccountingId
                        if (response.data.trademarkOpposition.invoiceClientAccountingId > 0)
                            accountingId = response.data.trademarkOpposition.invoiceClientAccountingId
                        this.clientAccountingId = accountingId

                        const invoiceData = {
                            clientAccountingId: accountingId,
                            moduleId: 6,
                            recordId: this.id
                        }
                        const invoiceController = {
                            name: 'ClientAccounting',
                            actionName: 'GetInvoiceCountByClientAccountId'
                        }
                        const invoicePayload = {
                            data: invoiceData,
                            controller: invoiceController
                        }
                        this.$store.dispatch('moduleAuth/customService', invoicePayload).then((invoiceResponse) => {
                            this.invoicesToBePaid = invoiceResponse.data.invoicesToBePaidCount
                            this.allInvoices = invoiceResponse.data.allInvoiceCount
                            this.allInvoicesRelatedToThisJob = invoiceResponse.data.allInvoicesRelatedToThisJobCount
                            this.invoicesToBePaidForThisJob = invoiceResponse.data.invoicesToBePaidForThisJobCount
                        })

                    } else {
                        this.invoicesToBePaid = 0
                        this.allInvoices = 0;
                        this.allInvoicesRelatedToThisJob = 0;
                        this.invoicesToBePaidForThisJob = 0;
                    }
                } else {
                    this.$bvModal.hide('trademarkOppositionFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadTrademarkOppositionWordFile(documentId, name) {
            const data = {
                moduleId: 2,
                documentId: documentId,
                trademarkOppositionId: this.id
            }
            const controller = {
                name: 'TrademarkOpposition',
                actionName: 'DownloadTrademarkOppositionWordFile'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.trademarkOppositionInvoiceClientSelect === null) {
                this.invoiceClientId = 0
            } else {
                this.invoiceClientId = this.trademarkOppositionInvoiceClientSelect.clientAccountingId
            }
            const data = {
                id: this.id,
                applicationDate: this.applicationDate,
                bulletinDate: this.bulletinDate,
                bulletinNo: this.bulletinNo,
                agentReferenceNo: this.agentReferenceNo,
                applicationNo: this.applicationNo,
                date: this.oppositionDate,
                name: this.name,
                recordNo: this.trademarkOppositionRefCode,
                niceClass: this.niceClassSelectString,
                clientAccountingId: this.trademarkOppositionAgentSelect.clientAccountingId,
                employeeId: localStorage.getItem('EmployeeId'),
                invoiceClientAccountingId: this.invoiceClientId,
                deadline: this.deadlineDate,
                holderCode: this.holderCode,
                holderName: this.holderName,
                attorneyName: this.attorneyName,
                trademarkOppositionStatusId: this.trademarkOppositionStatusSelect,
                logo: this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
            }
            const controller = {
                name: 'TrademarkOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    if (this.id == 0) {
                        var base64 = this.image.replace(/^data:image\/(png|jpg|jpeg);base64,/, '')
                        const data = {
                            fileName: this.nationalTrademarkId,
                            base64: base64
                        }
                        const controller = {
                            name: 'TrademarkOpposition',
                            actionName: 'SaveTrademarkOppositionLogo'
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {

                            }
                        })
                    }
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.getTrademarkOppositionList()
                            this.$SaveAlert()
                            if (this.id == 0) {
                                this.id = response.data.trademarkOppositionId
                                this.trademarkOppositionRefCode = response.data.recordNo
                                this.hiddenControl = false
                            }
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        prepareTrademarkOppositionMail(mailTemplateId) {
            const data = {
                mailTemplateId: mailTemplateId,
                trademarkOppositionId: this.id
            }
            const controller = {
                name: 'TrademarkOpposition',
                actionName: 'PrepareTrademarkOppositionMail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    // this.$downloadFile(name, response.data.base64, 'docx')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        setClientIdWithInvoiceClientCheck(event) {
            if (event != null) {
                if (this.trademarkOppositionInvoiceClientSelect.forInvoice == false) {
                    this.clientId = null;
                    this.trademarkOppositionInvoiceClientSelect = null;
                    this.$ForInvoiceAlert()
                } else {
                    this.clientId = event.clientId
                }
            } else {
                this.clientId = null;
            }
        },
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .dark-layout .modal .modal-content,
[dir] .dark-layout .modal .modal-body,
[dir] .dark-layout .modal .modal-footer {
    background-color: #272e3f !important;
    border-color: #3b4253;
}

[dir] body.dark-layout .b-calendar-grid.form-control .small {
    background: #161d31;
    display: none !important;
}

.b-calendar .b-calendar-grid-help {
    color: #6e6b7b !important;
    display: none;
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}

.dropdown-scroll {
    max-height: 200px;
    /* Dropdown içeriği için maksimum yükseklik */
    overflow-y: auto;
    /* Yatay kaydırma çubuğunu etkinleştir */
}

.agentInput {
    max-width: 87em !important;
    width: 86em !important;
}
</style>
