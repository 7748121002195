<template>
<div>
    <!-- modal -->
    <b-modal id="clientResponsibleFormModal" centered size="lg" no-close-on-backdrop ref="clientResponsibleFormModal" :title=" $t('clientResponsible.responsible_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col>
                    <b-form>
                        <label for="responsible-name-input">{{ $t('clientResponsible.responsible_name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('clientResponsible.responsible_name')" rules="required">
                                <b-form-input size="sm" id="responsible-name-input" v-model="responsibleName" maxlength="100" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col>
                    <b-form>
                        <label for="responsible-email-input">{{ $t('clientResponsible.responsible_mail') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('clientResponsible.responsible_mail')" rules="required|email">
                                <b-form-input  size="sm" id="responsible-email-input" placeholder="test@stock.com" v-model="email" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label for="client-mail-contact-type">{{ $t('clientResponsible.contact_type') }}</label>
                    <b-form>
                        <validation-provider :name=" $t('clientResponsible.contact_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <b-form-select  size="sm" :state="errors.length > 0 ? false:null" v-model="mailContactTypeSelect" value-field="id" text-field="name" :options="mailContactTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col>
                    <label for="responsible-field-input">{{ $t('clientAccounting.responsible_field') }}</label>
                    <b-form>
                        <validation-provider :name=" $t('clientAccounting.responsible_field')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <b-form-select  size="sm" :state="errors.length > 0 ? false:null" v-model="responsibleFieldSelect" value-field="id" text-field="name" :options="responsibleFieldOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>

            </b-row>
        </validation-observer>
        <b-row class="mt-1">
            <b-col class="col-lg-8">
                <b-form>
                    <validation-provider :name=" $t('clientResponsible.owner_client')" #default="{ errors }" rules="required">
                        <b-form-group :label=" $t('clientResponsible.owner_client')" label-for="ownerClient" :state="errors.length > 0 ? false:null">
                            <v-select id="ownerClient" v-model="clientOwnerSelect" :options="clientOwnerOption" label="name" @search="onSearch">
                            </v-select>
                            <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                                {{ errors[0] }}
                            </b-form-invalid-feedback>
                        </b-form-group>
                    </validation-provider>
                </b-form>
            </b-col>
            <b-col class="col-lg-4">
                <label for="country-isReceiveMail-input">{{ $t('clientResponsible.is_receive_mail') }}</label>
                <b-form-checkbox class="custom-control-success" name="country-is-receive-mail-input" id="country-is-receive-mail-input" v-model="isReceiveMail" :checked="isReceiveMail" @change="check($event)" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>

        </b-row>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getClientResponsibleList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            responsibleName: '',
            isReceiveMail: false,
            responsibleFieldSelect: 0,
            responsibleFieldOption: [],
            clientOwnerSelect: 0,
            clientOwnerOption: [],
            mailContactTypeSelect: 0,
            mailContactTypeOption: [],
            email: '',
            clientId: 0
        }
    },
    methods: {
        check(e) {
            this.isReceiveMail = e.target.checked;
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    // clientId: this.clientId,
                    name: search
                }
                const controller = {
                    name: 'Client',
                    actionName: 'GetOwnerClientByName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.clientOwnerOption = response.data.clientList
                    loading(false)
                })
            }
        },
        showInfo(id, clientId) {
            this.$refs['clientResponsibleFormModal'].show()
            this.id = id
            this.clientId = clientId;
            const data = {
                id: this.id
            }
            const controller = {
                name: 'ClientResponsible'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.responsibleName = response.data.clientResponsible.name
                    this.responsibleFieldSelect = response.data.clientResponsible.responsibleFieldId
                    this.responsibleFieldOption = response.data.responsibleFieldList
                    // this.clientOwnerSelect = response.data.clientResponsible.ownerClientId
                    if (response.data.clientResponsible.ownerClientId > 0) {
                        const data = {
                            clientId: response.data.clientResponsible.ownerClientId,
                        }
                        const controller = {
                            name: 'Client',
                            actionName: 'GetOwnerClientByName'
                        }
                        const payload = {
                            data: data,
                            controller: controller
                        }
                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            this.clientOwnerOption = response.data.clientList
                            this.clientOwnerSelect = response.data.clientList[0]
                        })

                    } else {
                        this.clientOwnerSelect = 0
                        this.clientOwnerOption = []
                    }
                    this.mailContactTypeSelect = response.data.clientResponsible.mailContactTypeId
                    this.mailContactTypeOption = response.data.mailContactTypeList
                    this.email = response.data.clientResponsible.email
                    this.isReceiveMail = response.data.clientResponsible.unsubscribe
                    this.mailContactTypeOption.splice(0, 0, this.$nullSelected())
                    this.responsibleFieldOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('clientResponsibleFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                    this.clientOwnerSelect = 0
                }
            })
        },
        addOrUpdate() {
            const data = {
                id: this.id,
                clientId: this.clientId,
                ownerClientId: this.clientOwnerSelect.id,
                name: this.responsibleName,
                mailContactTypeId: this.mailContactTypeSelect,
                responsibleFieldId: this.responsibleFieldSelect,
                email: this.email,
                unsubscribe: this.isReceiveMail
            }
            const controller = {
                name: 'ClientResponsible'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('clientResponsibleFormModal')
                            this.getClientResponsibleList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
