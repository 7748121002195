<template>
    <div>
        <b-modal id="annuityDetailStatusContentModal" ref="annuityDetailStatusContentModal" centered size="lg" no-close-on-backdrop :title="$t('annuityDetailStatusContent.title')" hide-footer>
            <div>
                <b-card>
                    <ul v-if="rows.length > 0" style="list-style-type: none; padding-left: 0;">
                        <li v-for="(item, index) in rows" :key="index">
                          {{ index + 1 }} - {{ item.replacedAnnuityDetailText }}
                        </li>
                    </ul>
                    <ul v-else style="list-style-type: none; text-align: center">
                        <li>
                          {{$t('annuityDetailStatusContent.null_object') }}
                        </li>
                    </ul>
                </b-card>
              </div>
        </b-modal>
    </div>
    </template>
    
    <script>
    export default {
        props: {
            getAnnuityList: {
                type: Function
            },
        },
        data() {
            return {
                annuityId :0,
                rows: []
            }
        },
        mounted() {
            this.getAnnuityList()
        },
        methods: {
            showInfo(annuityId) {
                this.$refs['annuityDetailStatusContentModal'].show()
                this.annuityId = annuityId
                const controller = { name: 'AnnuityDetail', actionName: 'GetAnnuityDetailStatusReportList' }
                const data = { annuityId: this.annuityId}
                const payload = { data: data, controller: controller}
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.rows = []
                        response.data.annuityDetailStatusReportList.forEach(element => {
                            this.rows.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
            },
        },
    }
    </script>
    
    <style lang="scss">
    .excel-css {
        float: right;
        margin-right: 1em;
        width: 9.5em;
        color: #7367f0;
        border: 1px solid #7367f0;
        background-color: transparent;
        margin-left: 1em;
        height: 2em;
        border-radius: 0.358rem;
    }
    
    .tableSize {
        font-size: 13px !important;
        border-collapse: collapse;
        width: 100%;
        max-width: 100%;
        table-layout: auto;
    }
    </style>
    