<template>
<div>
    <b-modal id="totalImageUploadFormModal" centered size="lg" no-close-on-backdrop ref="totalImageUploadFormModal" :title="$t('totalImageUpload.info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate">
        <validation-observer ref="simpleRules">
            <b-row>
                <b-col class="col-6">
                    <b-form style="margin-top: 0.2em">
                        <validation-provider :name="$t('totalImageUpload.record_no')" #default="{ errors }" :rules="{ excluded: 0 }">
                            <b-form-group :label="$t('totalImageUpload.record_no')" label-for="total-image-upload-record-no-input"
                              :state="errors.length > 0 ? false : null">
                              <v-select id="total-image-upload-record-no-input" size="sm" v-model="recordName" label="recordName" @input="setValues" @search="onFastSearch"
                              :options="searchRecordOption.map(option => ({ ...option, recordName: option.recordName}))" >
                              </v-select>
                              <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                {{ errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                    </b-form>

                </b-col>   
                <!-- //STOCK-1176 -->
                <b-col class="col-6">
                    <label for="document-date">{{ $t('documents.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="1" v-model="date"></CustomDatePicker>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <b-form>
                        <validation-provider :name=" $t('totalImageUpload.documents_type')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="total-image-upload-documents-type-input">{{ $t('totalImageUpload.documents_type') }}</label>
                            <b-form-select :disabled="moduleId == 0" @change="getDocumentDetailList()" size="sm" :state="errors.length > 0 ? false:null" v-model="documentTypeSelect" value-field="id" text-field="name" :options="documentTypeOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col>
                    <b-form>
                        <validation-provider :name=" $t('totalImageUpload.documents_content')" :rules="{ excluded:0}" v-slot="{ errors }">
                            <label for="total-image-upload-documents-content-input">{{ $t('totalImageUpload.documents_content') }}</label>
                            <b-form-select :disabled="documentTypeSelect == 0" size="sm" :state="errors.length > 0 ? false:null" v-model="documentContentSelect" value-field="id" text-field="name" :options="documentContentOption" />
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-form>
                        <label for="total-image-upload-note-input">{{ $t('totalImageUpload.note') }}</label>
                        <b-form-group>
                            <b-form-textarea id="total-image-upload-note-input" :placeholder=" $t('totalImageUpload.note')" v-model="note" rows="3" />
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col>
                    <label for="total-image-upload-file-upload-input">{{ $t('totalImageUpload.file_upload') }}</label>
                    <div 
                        class="drop-zone" 
                        @drop.prevent="handleDrop" 
                        @dragover.prevent="handleDragOver"
                        @dragleave.prevent="handleDragLeave"
                        :class="{ 'drop-zone--over': isDragging }"
                    >
                        <div class="drop-zone-text" v-if="isDragging">{{ $t('totalImageUpload.drop') }}</div>
                        <div class="drop-zone-text" v-else>{{ $t('totalImageUpload.select_images') }}</div>
                        <input 
                            type="file" 
                            multiple 
                            class="file-input" 
                            @change="uploadMultipleFiles"
                            ref="fileInput"
                        />
                    </div>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    data() {
        return {
            id: 0,
            recordId: 0,
            moduleId: 0,
            recordName:0,
            searchRecordOption:[],
            documentTypeSelect: 0,
            documentTypeOption: [],
            documentContentSelect: 0,
            documentContentOption: [],
            name: '',
            note: '',
            documentFile: '',
            documentType: '',
            documentName: '',
            documentTypeList: [],
            documentList: [],
            date:"",
            fileName: '',
            fileType: '',
            fileBase: '',
            isDragging: false
        }
    },
    mounted() {
        this.getDocumentTypeList()
    },
    methods: {
        showInfo() {
            if(this.date == ""){
                setTimeout(() => {  this.date = new Date().toJSON() }, 200);
            }
            this.documentTypeSelect = 0
            this.documentContentSelect = 0
            this.note = ''
            this.documentList = []
            this.$refs['totalImageUploadFormModal'].show()
        },
        async uploadMultipleFiles(event) {
            try {
                this.documentList = [];
                let files;
                
                // Sürükle bırak veya dosya seçici kontrolü
                if (event instanceof DragEvent) {
                    files = event.dataTransfer.files;
                } else if (event.target && event.target.files) {
                    files = event.target.files;
                } else {
                    console.error('Geçersiz dosya formatı:', event);
                    return;
                }

                // Her dosya için base64'e çevirme işlemi
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    try {
                        const base64 = await this.fileToBase64(file);
                        this.documentList.push({
                            name: file.name.substring(0, file.name.lastIndexOf('.')),
                            contentType: file.name.split('.').pop(),
                            base64: base64
                        });
                        
                    } catch (err) {
                        console.error(`Dosya işleme hatası (${file.name}):`, err);
                    }
                }

            } catch (error) {
                console.error('Dosya yükleme hatası:', error);
                this.$WarningAlert('Dosya yükleme sırasında bir hata oluştu');
            }
        },
        fileToBase64(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const base64String = reader.result.split(',')[1];
                    resolve(base64String);
                };
                reader.onerror = reject;
                reader.readAsDataURL(file);
            });
        },
        getDocumentDetailList() {
            this.documentContentOption = []
            if (this.documentTypeSelect > 0) {
                const data = {
                    id: this.documentTypeSelect
                }
                const controller = {
                    name: 'DocumentTypeDetail',
                    actionName: 'GetDocumentTypeDetailListByDocumentTypeId'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        response.data.documentTypeDetailList.forEach(element => {
                            this.documentContentOption.push(element)
                        })
                    } else {
                        this.$WarningAlert(response.data.resultMessage)
                    }
                })
                this.documentContentOption.splice(0, 0, this.$nullSelected())
            }
        },
        findDocumentTypeList() {
            this.documentTypeOption = []
            if (this.moduleId > 0) {
                this.documentTypeOption = this.documentTypeList.filter(p => p.moduleId == this.moduleId)
                this.documentTypeOption.splice(0, 0, this.$nullSelected())
            }
        },
        onFastSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.fastSearch(loading, search, this);
            }
        },
        fastSearch(loading, search) {
            if (search.length > 2) {
                const data = {
                    recordNo: search,
                    isOnlyOperationRecord : true
                }

                const controller = {
                    name: 'Module',
                    actionName: 'GetRecordListByRecordNo'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.searchRecordOption = response.data.recordList
                    loading(false)
                })
            }
        },
        setValues(event) {
            this.moduleId = event.moduleId
            this.recordId = event.recordId
            if(this.moduleId > 0 && this.recordId > 0){
                this.findDocumentTypeList()
            }            
        },
        getDocumentTypeList() {
            this.documentTypeList = []
            const controller = {
                name: 'DocumentType'
            }
            const payload = {
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.documentTypeList.forEach(element => {
                        this.documentTypeList.push(element)                        
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {  
            this.$refs.simpleRules.validate().then(success => {
                if (this.documentList.length === 0) {
                    this.$WarningAlert(this.$t('alerts.base64_null'))
                }
                else {
                    if (success) {
                        let loader = this.$loading.show()
                        const documentList = this.documentList.map(fileInfo => ({
                            id: 0,
                            moduleId: this.moduleId,
                            recordId: this.recordId,
                            jurisdictionId: 1,
                            documentTypeDetailId: this.documentContentSelect,
                            documentTypeId: this.documentTypeSelect,
                            note: this.note,
                            name: fileInfo.name,
                            type: fileInfo.contentType,
                            base64: fileInfo.base64,
                            date: this.date,
                            employeeId: localStorage.getItem('EmployeeId')
                        }));

                        const data = { documentList: documentList }

                        const controller = { name: 'Document', actionName: 'SaveMultiDocument' }
                        const payload = { data: data, controller: controller }

                        this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                            if (response.data.resultStatus == true) {
                                this.$bvModal.hide('totalImageUploadFormModal')
                                loader.hide()
                                this.moduleId = 0
                                this.documentTypeSelect = 0
                                this.documentContentSelect = 0
                                this.note = ''
                                this.$SaveAlert()
                            } else {
                                this.$WarningAlert(response.data.resultMessage)
                                loader.hide()
                            }
                        })
                    }
                }
            })
        },
        //Sürükle Bırak için yazılan üç method
        handleDragOver(e) {
            this.isDragging = true;
        },
        handleDragLeave(e) {
            this.isDragging = false;
        },
        handleDrop(e) {
            e.preventDefault();
            this.isDragging = false;
            this.uploadMultipleFiles(e);
        },
    }
}
</script>

<style>
[dir] .vs__selected-options {
    padding: 0.2px;
    font-size: 0.9em;
}

.drop-zone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    background: #f8f9fa;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.drop-zone:hover {
    background: #f1f3f5;
}

.drop-zone--over {
    border-color: #6c5ce7;
    background-color: rgba(108, 92, 231, 0.05);
}

.drop-zone-text {
    color: #666;
    font-size: 1.1em;
}

.file-input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
}
</style>
