<template>
    <div>
        <validation-observer ref="simpleRules">
            <!--For normal date form-->
            <div v-if="datePickerValidateOption == 0">
                <b-input-group  class="mb-1">
                    <b-form-input size="sm" v-model="newDate" type="date" @paste="copyDate" class="no-calendar"></b-form-input>
                    <b-input-group-append>
                        <b-form-datepicker inputmode="true" onstrainInput="false" button-variant="outline-primary" class="datepickerStyle" 
                        size="sm" v-model="newDate" defaultDate: null 
                        reset-button today-button :label-today-button="$t('dateTime.today')" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" 
                        :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" button-only right />
                    </b-input-group-append>
                </b-input-group>
            </div>
            <!--For valid date form-->
            <div v-else-if="datePickerValidateOption == 1">
                <validation-provider #default="{ errors }" rules="required" :name="$t('others.date_is_empty')">
                    <b-input-group  class="mb-1">
                        <b-form-input size="sm" v-model="newDate" type="date" @paste="copyDate" class="no-calendar"></b-form-input>
                        <b-input-group-append>
                            <b-form-datepicker inputmode="true" onstrainInput="false" button-variant="outline-primary" class="datepickerStyle" 
                            size="sm" v-model="newDate" defaultDate: null 
                            reset-button today-button :label-today-button="$t('dateTime.today')" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" 
                            :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" button-only right :state="errors.length > 0 ? false:null" />
                        </b-input-group-append>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </b-input-group>
                </validation-provider>
            </div>
            <!--For disabled date form-->
            <div v-if="datePickerValidateOption == 2">
                <b-input-group  class="mb-1">
                    <b-form-input disabled size="sm" v-model="newDate" type="date" @paste="copyDate" class="no-calendar"></b-form-input>
                    <b-input-group-append>
                        <b-form-datepicker disabled inputmode="true" onstrainInput="false" button-variant="outline-primary" class="datepickerStyle" 
                        size="sm" v-model="newDate" defaultDate: null 
                        reset-button today-button :label-today-button="$t('dateTime.today')" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }" 
                        :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" button-only right />
                    </b-input-group-append>
                </b-input-group>
            </div>
        </validation-observer>
    </div>

</template>

<script>
import moment from 'moment';
export default {
    name:"CustomDatePicker",
    props: {
        value: { type: String},
        datePickerValidateOption: {type: Number}
    },
    watch: {
        newDate(newValue) {
            if(newValue == '')
                this.$emit('input', null)
            else
                this.$emit('input', newValue)
        },
        value(newValue){
            if(newValue != null && newValue != this.newDate)
                this.newDate = newValue.split("T")[0]  
        }
    },
    data() {
        return {
            newDate:""
        }
    },
    methods: {
        copyDate(event){
            var date = moment(event.clipboardData.getData('text'), 'DD-MM-YYYY')

            if(moment(date).isValid())
                this.newDate = date.format('YYYY-MM-DD')
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}

.no-calendar::-webkit-calendar-picker-indicator {
  display: none;
}
</style>
