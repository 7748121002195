<template>
<div>
    <!-- modal -->
    <b-modal id="multiDailyworkSaveFormModal" centered size="xl" no-close-on-backdrop ref="multiDailyworkSaveFormModal" :title="$t('dailywork.invoice_and_accounting')  +' '+recordNo" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="saveControl">
        <b-row>
            <b-col class="col-lg-12 mb-1">
                <vue-good-table max-height="150px" @on-row-dblclick="addSelect" :columns="columns" :rows="serviceOption" :sort-options="{ enabled: false}" styleClass="vgt-table condensed tableSize" theme="my-theme">
                    <template slot="table-column" slot-scope="props">
                        <!-- Burada sadece ilk sütunun başlığını göstermek için kontrol ediyoruz -->
                        <span v-if="props.column.field !== 'first_column_field'">
                        </span>
                    </template>
                </vue-good-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="multi-dailywork-save-date-input">{{ $t('multiDailyworkSave.date') }}</label>
                    <CustomDatePicker :datePickerValidateOption="0" v-model="date"></CustomDatePicker>
                </b-form>
            </b-col>
            <b-col class="col-lg-2">
                <b-form>
                    <label for="multi-dailywork-save-amount-input">{{ $t('multiDailyworkSave.amount') }}</label>
                    <b-form-group>
                        <b-form-input size="sm" id="multi-dailywork-save-amount-input" disabled v-model="amount" type="number" />
                    </b-form-group>
                </b-form>
            </b-col>
            <b-col class="col-md-2">
                <label for="multi-dailywork-save-is-proforma-input"> {{ $t('multiDailyworkSave.proforma') }} </label>
                <b-form-checkbox id="multi-dailywork-save-is-proforma-input" :checked="isProforma" class="custom-control-success" name="check-button" v-model="isProforma" switch>
                    <span class="switch-icon-left">
                        <feather-icon icon="CheckIcon" />
                    </span>
                    <span class="switch-icon-right">
                        <feather-icon icon="XIcon" />
                    </span>
                </b-form-checkbox>
            </b-col>            
        </b-row>
        <validation-observer ref="simpleRules">
            <b-row v-if="isProforma == false">
                <b-col class="col-lg-2">
                    <label for="dailywork-invoice-accounting-credit-card-input">{{ $t('multiDailyworkSave.credit_card') }}</label>
                    <b-form>
                        <validation-provider v-slot="{ errors }" :name="$t('multiDailyworkSave.credit_card')" :rules="{ excluded:0}" >
                            <b-form-select size="sm" v-model="creditCardSelect" :options="creditCardOption" value-field="id" name="dailywork-invoice-accounting-credit-card-input" text-field="name" :state="errors.length > 0 ? false:null" />
                            <small class="text-danger">{{  errors[0]  }}</small>
                        </validation-provider>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <label v-if="creditCardSelect == 12" for="dailywork-invoice-accounting-invoice-receipt-no-input">{{ $t('multiDailyworkSave.receipt_no') }}</label>
                        <label v-else for="dailywork-invoice-accounting-invoice-receipt-no-input">{{ $t('multiDailyworkSave.provision_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('multiDailyworkSave.receipt_no')" rules="required">
                                <b-form-input id="dailywork-invoice-accounting-invoice-receipt-no-input" size="sm" v-model="receiptNo" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <label v-if="creditCardSelect == 12" for="dailywork-invoice-accounting-receipt-date-input">{{ $t('multiDailyworkSave.receipt_date') }}</label>
                    <label v-else for="dailywork-invoice-accounting-receipt-date-input">{{ $t('multiDailyworkSave.provision_date') }}</label>
                    <b-form>
                        <b-form-group>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="receiptDate"></CustomDatePicker>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <b-form>
                        <label for="dailywork-invoice-accounting-document-no-input">{{ $t('multiDailyworkSave.document_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('multiDailyworkSave.document_no')" rules="required">
                                <b-form-input id="dailywork-invoice-accounting-document-no-input" size="sm" v-model="documentNo" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-lg-2">
                    <label for="dailywork-invoice-accounting-document-date-input">{{ $t('multiDailyworkSave.document_date') }}</label>
                    <b-form>
                        <b-form-group>
                            <CustomDatePicker :datePickerValidateOption="1" v-model="documentDate"></CustomDatePicker>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
        <vue-good-table  min-height="150px" @on-row-dblclick="removeSelect" :columns="columns" :rows="selectList" :sort-options="{ enabled: false}" styleClass="vgt-table condensed tableSize" 
            theme="my-theme">
            <template slot="table-row" slot-scope="props">
                <!-- Column: Process -->
                <span v-if="props.column.field === 'count'">
                    <span>
                        <b-form-input size="sm" id="dailywork-not-accounting-count-input" :value="props.row.count" @change="changeCount(props.row, $event)" type="number" maxlength="50" />
                    </span>
                </span>
            </template>
        </vue-good-table>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getDailyworkList: {
            type: Function
        },
    },
    data() {
        return {
        columns: [
          {
            label: this.$t('multiDailyworkSave.service'),
            field: `service`,
          },
          {
            label: this.$t('multiDailyworkSave.count'),
            field: `count`,
          },
          {
            label: this.$t('multiDailyworkSave.tpmk_price'),
            field: `tpmkPrice`,
            width: '15em'
          },
        ],
        rows: [],
        id: 0,
        serviceOption: [],
        serviceSelect: 0,
        selectList: [],
        creditCardOption: [],
        creditCardSelect: 0,
        amount: 0,
        receiptNo: '',
        receiptDate: null,
        date: null,
        documentDate: null,
        isProforma : false,
        documentNo: '',
        recordNo: '',
        dateOption: {
            date: true,
            delimiter: '.',
            datePattern: ['d', 'm', 'Y'],
        },
    }
    },
    methods: {
        addSelect(params) {
            const index = this.serviceOption.findIndex((i) => {
                 return i.service === params.row.service;
            })
            this.serviceOption.splice(index, 1)
            this.selectList.push(params.row)
            this.calculatePrice()
        },
        removeSelect(params) {
            const index = this.selectList.findIndex((i) => {
                 return i.service === params.row.service;
            })
            this.selectList.splice(index, 1)
            this.serviceOption.unshift(params.row)
            this.calculatePrice()
        },
        changeCount(params, event)
        {
            const index = this.selectList.findIndex((i) => {
                 return i.service === params.service;
            })
            this.selectList[index].count = event
            this.calculatePrice()
        },
        calculatePrice()
        {          
            this.amount = 0
            this.selectList.forEach(item => {
                this.amount = (parseFloat(this.amount) + parseFloat(item.tpmkPrice * item.count)).toFixed(2)
            });
        },
        showInfo(id, moduleId, recordId, paymentType) {
            this.$refs['multiDailyworkSaveFormModal'].show()
            this.serviceOption = []
            this.selectList = []

            this.id = id
            this.recordId = recordId
            this.moduleId = moduleId
            this.paymentTypeId = paymentType
            const data = {
                id: this.id,
                moduleId: this.moduleId,
                recordId: this.recordId,
                paymentTypeId: this.paymentTypeId,
            }
            const controller = {
                name: 'DailyWork',
                actionName: 'GetMultiDailyworkInfo'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.serviceOption = response.data.priceListDetailForDailyWorkList
                    this.creditCardOption = response.data.creditCardList
                    this.isProforma = response.data.multiDailyWork.isProforma
                    this.receiptNo = response.data.multiDailyWork.receiptNo
                    this.receiptDate = response.data.multiDailyWork.receiptDate
                    this.date = response.data.multiDailyWork.date
                    this.documentNo = response.data.multiDailyWork.documentNo
                    this.documentDate = response.data.multiDailyWork.documentDate
                    this.recordNo = response.data.multiDailyWork.recordNo
                    this.creditCardOption.splice(0, 0, this.$nullSelected())
                } else {
                    this.$bvModal.hide('multiDailyworkSaveFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },       
        saveControl() {
            if(this.isProforma == false){                
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                    this.addOrUpdate()
                    }
                })
            } 
            else {
                this.addOrUpdate()
            }
        },
        addOrUpdate(){
            if(this.selectList.length == 0){
                    this.$ErrorSaveAlert()
                    return
                }
            const data = {
                        moduleId: this.moduleId,
                        recordId: this.recordId,
                        PriceListDetailForDailyWorkList: this.selectList,
                        date: this.date,
                        CreditCardId: this.creditCardSelect,
                        receiptNo: this.receiptNo,
                        receiptDate: this.receiptDate,
                        documentNo: this.documentNo,
                        documentDate: this.documentDate,
                        isProforma: this.isProforma,
                        employeeId: localStorage.getItem('EmployeeId'),
                    }
                    const controller = {
                        name: 'DailyWork',
                        actionName: 'SaveMultiDailyWork'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }

                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                                if (response.data.resultStatus == true) {
                                    this.$bvModal.hide('multiDailyworkSaveFormModal')
                                    this.getDailyworkList()
                                    this.$SaveAlert()
                                } else {
                                    this.$WarningAlert(response.data.resultMessage)
                                }
                            })
                        }
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}
.datepickerStyle{
    max-height: 2.2em;
    font-family: fangsong;
}

#multiDailyworkSaveFormModal .vgt-table tbody tr {
    cursor: pointer;
    transition: background-color 0.2s ease;
}

#multiDailyworkSaveFormModal .vgt-table tbody tr:hover {
    background-color: rgba(115, 103, 240, 0.1) !important;
}

#multiDailyworkSaveFormModal .vgt-table tbody td {
    transition: background-color 0.2s ease;
}

#multiDailyworkSaveFormModal .vgt-table tbody td:hover {
    background-color: rgba(115, 103, 240, 0.15) !important;
}
</style>
