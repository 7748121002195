var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"clientResponsibleFormModal",attrs:{"id":"clientResponsibleFormModal","centered":"","size":"lg","no-close-on-backdrop":"","title":_vm.$t('clientResponsible.responsible_info'),"cancel-title":_vm.$t('others.cancel_title'),"ok-title":_vm.$t('others.ok_title'),"cancel-variant":"outline-secondary"},on:{"ok":function($event){$event.preventDefault();return _vm.addOrUpdate($event)}}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',[_c('b-form',[_c('label',{attrs:{"for":"responsible-name-input"}},[_vm._v(_vm._s(_vm.$t('clientResponsible.responsible_name')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('clientResponsible.responsible_name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"responsible-name-input","maxlength":"100","state":errors.length > 0 ? false:null},model:{value:(_vm.responsibleName),callback:function ($$v) {_vm.responsibleName=$$v},expression:"responsibleName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-col',[_c('b-form',[_c('label',{attrs:{"for":"responsible-email-input"}},[_vm._v(_vm._s(_vm.$t('clientResponsible.responsible_mail')))]),_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('clientResponsible.responsible_mail'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"size":"sm","id":"responsible-email-input","placeholder":"test@stock.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":"client-mail-contact-type"}},[_vm._v(_vm._s(_vm.$t('clientResponsible.contact_type')))]),_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('clientResponsible.contact_type'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","options":_vm.mailContactTypeOption},model:{value:(_vm.mailContactTypeSelect),callback:function ($$v) {_vm.mailContactTypeSelect=$$v},expression:"mailContactTypeSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',[_c('label',{attrs:{"for":"responsible-field-input"}},[_vm._v(_vm._s(_vm.$t('clientAccounting.responsible_field')))]),_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('clientAccounting.responsible_field'),"rules":{ excluded:0}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"size":"sm","state":errors.length > 0 ? false:null,"value-field":"id","text-field":"name","options":_vm.responsibleFieldOption},model:{value:(_vm.responsibleFieldSelect),callback:function ($$v) {_vm.responsibleFieldSelect=$$v},expression:"responsibleFieldSelect"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{staticClass:"col-lg-8"},[_c('b-form',[_c('validation-provider',{attrs:{"name":_vm.$t('clientResponsible.owner_client'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('clientResponsible.owner_client'),"label-for":"ownerClient","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"ownerClient","options":_vm.clientOwnerOption,"label":"name"},on:{"search":_vm.onSearch},model:{value:(_vm.clientOwnerSelect),callback:function ($$v) {_vm.clientOwnerSelect=$$v},expression:"clientOwnerSelect"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{staticClass:"col-lg-4"},[_c('label',{attrs:{"for":"country-isReceiveMail-input"}},[_vm._v(_vm._s(_vm.$t('clientResponsible.is_receive_mail')))]),_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"name":"country-is-receive-mail-input","id":"country-is-receive-mail-input","checked":_vm.isReceiveMail,"switch":""},on:{"change":function($event){return _vm.check($event)}},model:{value:(_vm.isReceiveMail),callback:function ($$v) {_vm.isReceiveMail=$$v},expression:"isReceiveMail"}},[_c('span',{staticClass:"switch-icon-left"},[_c('feather-icon',{attrs:{"icon":"CheckIcon"}})],1),_c('span',{staticClass:"switch-icon-right"},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }