<template>
<div>
    <invoice-form ref="invoiceForm"></invoice-form>
    <reminder-form :getAgingReportList="getAgingReportList" ref="reminderForm"></reminder-form>

    <b-row class="align-items-center">
        <b-col class="d-flex align-items-center col-lg-9">
            <b-col class="col-lg-3">
                <label for="aging-report-account-number-input"> {{ $t('agingReport.account_number') }} </label>
                <b-form-input id="aging-report-account-number-input" size="sm" v-model="accountNumber" v-on:keyup.enter="getAgingReportList()" :placeholder="$t('agingReport.account_number')" type="text" class="d-inline-block mr-1" />
            </b-col>
            <b-col class="col-lg-3">
                <label for="aging-report-agent-name-input"> {{ $t('agingReport.agent_name') }} </label>
                <b-form-input id="aging-report-agent-name-input" size="sm" v-model="agentName" v-on:keyup.enter="getAgingReportList()" :placeholder="$t('agingReport.agent_name')" type="text" class="d-inline-block mr-1" />
            </b-col>
            <b-col class="col-lg-3">
                <label for="aging-report-start-date-input"> {{ $t('agingReport.start_date') }} </label>
                <b-form-datepicker id="aging-report-start-date-input" today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('agingReport.start_date')" size="sm" v-model="startDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
            <b-col class="col-lg-3">
                <label for="aging-report-end-date-input"> {{ $t('agingReport.end_date') }} </label>
                <b-form-datepicker today-button :label-today-button="$t('dateTime.today')" :placeholder="$t('agingReport.end_date')" size="sm" v-model="endDate" class="mb1" defaultDate: null reset-button :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" :locale="$i18n.locale" :label-reset-button="$t('dateTime.reset')" :label-no-date-selected="$t('dateTime.select_date')" />
            </b-col>
        </b-col>
        <b-col class="col-md-3 custom-search d-flex justify-content-end mt-2">
            <div class="d-flex align-items-center">
                <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" v-on:keyup.enter="getAgingReportList()" @click="getAgingReportList()">{{ $t('payment.list') }}</b-button>
                <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>
                <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="clearSearchForm">{{ $t('others.clear') }}</b-button>
            </div>
        </b-col>
    </b-row>
    <b-row>
        <b-col>
            <app-collapse class="mb-1" accordion type="margin">
                <app-collapse-item :title="$t('others.search')">
                    <b-row>
                        <b-col class="col-lg-3">
                            <label for="aging-report-employee-input">{{ $t('agingReport.employee') }}</label>
                            <b-form-select v-model="employeeSelect" @change="getAgingReportList()" size="sm" value-field="id" text-field="name" :options="employeeOption" />
                        </b-col>
                        <b-col class="col-lg-3">
                            <label for="aging-report-start-total-input"> {{ $t('agingReport.start_total') }} </label>
                            <cleave id="aging-report-start-total-input" v-model="startTotal" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                        </b-col>
                        <b-col class="col-lg-3">
                            <label for="aging-report-end-total-input"> {{ $t('agingReport.end_total') }} </label>
                            <cleave id="aging-report-end-total-input" v-model="endTotal" class="cleaveStyle form-control" :options="cleaveOptions"></cleave>
                        </b-col>
                    </b-row>
                </app-collapse-item>
            </app-collapse>
        </b-col>
    </b-row>
    <!-- table -->
    <vue-good-table id="agingReportTable" ref="agingReportTable" :columns="columns" :rows="rows" styleClass="vgt-table condensed tableSize" :search-options="{
                enabled: true,
                externalQuery: searchTerm
              }" :select-options="{
                enabled: false,
                selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
                selectionInfoClass: 'custom-class',
                selectionText: 'rows selected',
                clearSelectionText: 'clear',
                disableSelectInfo: true, // disable the select info panel on top
                selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
              }" :pagination-options="{
                enabled: true,
                perPage: pageLength
              }" theme="my-theme">
        <div slot="emptystate">
            {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template slot="table-column" slot-scope="props">
            <span v-if="props.column.field === 'debitNo'">
                {{ $t('agingReport.no') }}
            </span>
            <span v-else-if="props.column.field === 'agentName'" class="text-nowrap">
                {{ $t('agingReport.agent_name') }}
            </span>
            <span v-else-if="props.column.field === 'accountingNumber'">
                {{ $t('agingReport.account_number') }}
            </span>
            <span v-else-if="props.column.field === 'module'" class="text-nowrap">
                {{ $t('agingReport.module') }}
            </span>
            <!-- <span v-else-if="props.column.field === 'recordNo'" class="text-nowrap">
                {{ $t('agingReport.record_no') }}
            </span> -->
            <span v-else-if="props.column.field === 'invoiceDate'">
                {{ $t('agingReport.invoice_date') }}
            </span>
            <span v-else-if="props.column.field === 'currency'" class="text-nowrap">
                {{ $t('agingReport.currency') }}
            </span>
            <span v-else-if="props.column.field === 'total'" class="text-nowrap">
                {{ $t('agingReport.total') }}
            </span>
            <span v-else-if="props.column.field === 'days'" class="text-nowrap">
                {{ $t('agingReport.days') }}
            </span>
            <span v-else-if="props.column.field === 'employee'" class="text-nowrap">
                {{ $t('agingReport.employee') }}
            </span>
        </template>

        <!-- Column: Table Column -->
        <template slot="table-row" slot-scope="props">
            <!-- Column: Agent Name -->
            <span v-if="props.column.field === 'debitNo'" class="text-nowrap">
                <b-badge class="mr-1" variant="primary" style="cursor: pointer;" @click="getShowInvoiceForm(props.row.invoiceId)">
                    <feather-icon icon="MousePointerIcon" />
                    <span>{{ props.row.debitNo }}</span>
                </b-badge>
                <b-badge variant="primary" :title="$t('others.show_invoice')" style="cursor: pointer;" @click="downloadInvoicePdf(props.row.invoiceId)">
                    <feather-icon icon="FileTextIcon" size="5" class="align-middle" />
                </b-badge>
            </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ total }} {{ $t('dataTable.record') }}</span>
                    <b-form-select v-model="pageLength" :options="['10','25','50']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})" />
                    <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
                </div>
                <div>
                    <b-pagination :value="1" :total-rows="total" v-model="pageNo" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value) => getAgingReportList()">
                        <template #prev-text>
                            <feather-icon icon="ChevronLeftIcon" size="18" />
                        </template>
                        <template #next-text>
                            <feather-icon icon="ChevronRightIcon" size="18" />
                        </template>
                    </b-pagination>
                </div>
            </div>
        </template>
    </vue-good-table>
</div>
</template>

<script>
export default {
    data() {
        return {
            dir: false,
            pageLength: 10,
            total: 0,
            pageNo: 1,
            name: '',
            accountNumber: '',
            startTotal: 0.0,
            endTotal: 0.0,
            startDate: null,
            endDate: null,
            cleaveOptions: {
                prefix: '',
                numeral: true,
                numeralPositiveOnly: true,
                noImmediatePrefix: true,
                rawValueTrimPrefix: true,
                numeralIntegerScale: 9,
                numeralDecimalScale: 2
            },
            columns: [{
                    label: this.$t('agingReport.no'),
                    field: `debitNo`,
                },
                {
                    label: this.$t('agingReport.agent_name'),
                    field: `agentName`,
                },
                {
                    label: this.$t('agingReport.account_number'),
                    field: `accountingNumber`,
                },
                //{
                //   label: this.$t('agingReport.module_name'),
                //    field: `module`,
                //},
                // {
                //     label: this.$t('agingReport.record_no'),
                //     field: `recordNo`,
                // },
                {
                    label: this.$t('agingReport.invoice_date'),
                    field: `invoiceDate`,
                    formatFn: this.$formatFn
                },
                {
                    label: this.$t('agingReport.currency'),
                    field: `currency`,
                },
                {
                    label: this.$t('agingReport.total'),
                    field: `total`,
                },
                {
                    label: this.$t('agingReport.days'),
                    field: `days`,
                },
                {
                    label: this.$t('agingReport.employee'),
                    field: `employee`,

                }
            ],
            rows: [],
            searchTerm: '',
            employeeSelect: 0,
            employeeOption: [],
            moduleSelect: 0,
            moduleOption: [],
            agentName: "",
        }
    },
    mounted() {
        this.startDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toJSON();
        this.getAgingReportList()
    },
    methods: {
        getAgingReportList() {
            this.rows = []
            const controller = {
                name: 'Invoice',
                actionName: 'GetInvoiceAgingReportList'
            }
            const data = {
                pageLength: this.pageLength,
                pageNo: this.pageNo,
                name: this.name,
                agentName: this.agentName,
                accountingNo: this.accountNumber,
                debitNote: this.debitNote,
                startTotal: this.startTotal,
                endTotal: this.endTotal,
                startDate: this.startDate,
                endDate: this.endDate,
                moduleId: this.molduleSelect,
                employeeId: this.employeeSelect
            }
            const payload = {
                controller: controller,
                data: data
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.moduleOption = response.data.moduleList
                    this.employeeOption = response.data.employeeList
                    this.moduleOption.splice(0, 0, this.$nullSelected())
                    this.employeeOption.splice(0, 0, this.$nullSelected())
                    this.total = response.data.total
                    response.data.invoiceAgingReportList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        getShowInvoiceForm(id) {
            this.$refs.invoiceForm.showInfo(id)
        },
        async downloadInvoicePdf(id) {
            const data = {
                invoiceId: id
            }
            const controller = {
                name: 'Invoice',
                actionName: 'DownloadProforma'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        exportExcel() {
            let controller = {
                name: 'Invoice',
                actionName: 'GetInvoiceAgingReportList'
            }
            let data = {
                pageLength: 5000,
                pageNo: 1,
                name: this.name,
                agentName: this.agentName,
                accountingNo: this.accountNumber,
                debitNote: this.debitNote,
                startTotal: this.startTotal,
                endTotal: this.endTotal,
                startDate: this.startDate,
                endDate: this.endDate,
                moduleId: this.molduleSelect,
                employeeId: this.employeeSelect
            }
            let payload = {
                controller: controller,
                data: data
            }

            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    controller = {
                        name: 'Invoice',
                        actionName: 'CanExport'
                    }
                    data = {
                        columnList: this.columns,
                        DataList: response.data.invoiceAgingReportList
                    }
                    payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$downloadFile("InvoiceAgingReport", response.data.base64, 'xlsx')
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        clearSearchForm() {
            this.accountNumber = '';
            this.agentName = '';
            this.startDate = null;
            this.endDate = null;
            this.employeeSelect = 0,
                this.startTotal = 0.0,
                this.endTotal = 0.0,

                // Tabloyu yenile
                this.getAgingReportList();
        }
    },
}
</script>

<style lang="scss" scoped>
.mr-05 {
    margin-right: 0.5rem !important;
}

.custom-search {
    .btn {
        margin-left: 5px;
    }
}
</style>
