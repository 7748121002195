<template>
<div>
    <!-- modal -->
    <b-modal id="invoiceFormModal" centered size="xl" no-close-on-backdrop ref="invoiceFormModal" :title=" $t('invoice.invoice_info')" hide-footer>
        <validation-observer ref="simpleRules">
            <invoice-add-item-form ref="invoiceAddItemForm" :showInvoiceInfo="showInfo"></invoice-add-item-form>
            <invoice-add-dailywork-form ref="invoiceAddDailyworkForm" :showInvoiceInfo="showInfo"></invoice-add-dailywork-form>
            <invoice-update-item-form ref="invoiceUpdateItemForm" :showInvoiceInfo="showInfo"></invoice-update-item-form>
            <invoice-discount-form ref="invoiceDiscountForm" :showInvoiceInfo="showInfo"></invoice-discount-form>

            <reminder-form ref="reminderForm"></reminder-form>
            <div class="custom-search mb-1">
                <b-row>
                    <b-col class="col-3">
                        <label>{{ $t('invoice.debitNo') }} : {{ this.debitNo }}</label>
                    </b-col>
                    <b-col class="col-9  d-flex justify-content-end">
                        <b-button v-if="invoiceStatusId == 1" variant="outline-primary" size="sm" @click.prevent="addOrUpdate">
                            {{ $t('others.ok_title') }}
                        </b-button>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.show_invoice')" @click="downloadInvoicePdf()">
                            <feather-icon icon="FileTextIcon" size="10" class="align-middle" />
                        </b-button>
                        <b-button v-if="invoiceStatusId == 1" variant="outline-warning" class="ml-1" size="sm" v-b-tooltip.html :title="$t('invoice.send_approval')" @click="sendApproval()">
                            <feather-icon icon="CompassIcon" size="10" class="align-middle" />
                        </b-button>
                        <b-button variant="outline-primary" class="ml-1" size="sm" v-b-tooltip.html :title="$t('others.reminder')" @click="getReminderInfo()">
                            <feather-icon icon="BellIcon" size="10" class="align-middle" />
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <!-- Grid bölünmesi -->
            <b-form-group :disabled="invoiceStatusId > 1">
                <div class="row">
                    <!-- Sol kısım -->
                    <div class="col-lg-5">
                        <b-row>
                            <b-col class="col-lg-6">
                                <b-alert v-if="accountingNote != ''" variant="warning" show>
                                    <div class="alert-body demo-inline-spacing" v-b-tooltip.hover.top="this.accountingNote">
                                        {{ $t('invoice.accounting_note') }}
                                    </div>
                                </b-alert>
                            </b-col>
                            <b-col class="col-lg-6">
                                <b-alert v-if="clientNote != ''" variant="warning" show>
                                    <div class="alert-body demo-inline-spacing" v-b-tooltip.hover.top="this.clientNote">
                                        {{ $t('invoice.client_note') }}
                                    </div>
                                </b-alert>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="col-lg-12">
                                <b-form>
                                    <label for="invoice-agent-input">{{ $t('invoice.agent') }}</label>
                                    <validation-provider :name="$t('invoice.agent')" #default="{ errors }" :rules="{ excluded: 0 }">
                                        <b-form-group :state="errors.length > 0 ? false : null">
                                            <v-select disabled size="sm" id="invoice-agent-input" v-model="agentSelect" :options="agentOption" @input="setClientId" label="clientName" @search="onSearch">
                                            </v-select>
                                            <b-form-invalid-feedback :state="errors.length > 0 ? false : null">
                                                {{ errors[0] }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </validation-provider>
                                </b-form>
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- Sol kısım devam eden form alanları -->
                            <b-col class="col-lg-6">
                                <label for="invoice-date-input">{{ $t('invoice.date') }}</label>
                                <CustomDatePicker :datePickerValidateOption="0" v-model="proformaDate"></CustomDatePicker>
                            </b-col>
                            <b-col class="col-lg-6">
                                <label for="invoice-invoice-accounting-billing-status-input">{{ $t('invoice.billing_status') }}</label>
                                <b-form-select size="sm" v-model="billingStatusSelect" value-field="id" text-field="name" :options="billingStatusOption" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- Sol kısım devam eden form alanları -->
                            <b-col class="col-lg-12 mt-1">
                                <label for="invoice-header-input">{{ $t('invoice.invoice_title') }}</label>
                                <b-form-textarea id="invoice-header-input" :placeholder=" $t('invoice.invoice_title')" size="sm" rows="3" v-model="header" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- Sol kısım devam eden form alanları -->
                            <b-col class="col-lg-6 mt-1">
                                <label for="invoice-agent-reference-no-input">{{ $t('invoice.agent_reference_no') }}</label>
                                <b-form-input id="invoice-agent-reference-no-input" v-model="agentReferenceNo" size="sm" maxlength="100" />
                            </b-col>
                            <b-col class="col-lg-6 mt-1">
                                <label for="invoice-stock-reference-no-input">{{ $t('invoice.stock_ref_no') }}</label>
                                <b-form-input id="invoice-stock-reference-no-input" v-model="stockReferenceNo" size="sm" maxlength="100" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- Sol kısım devam eden form alanları -->
                            <b-col class="col-lg-4 mt-1">
                                <label for="invoice-invoice-proforma-type-input">{{ $t('invoice.proforma_type') }}</label>
                                <b-form-select disabled size="sm" v-model="proformaTypeSelect" value-field="id" text-field="name" :options="proformaTypeOption" />
                            </b-col>

                            <b-col class="col-lg-4 mt-1">
                                <label for="invoice-note-type-input">{{ $t('invoice.note_type') }}</label>
                                <b-form-select disabled size="sm" v-model="noteTypeSelect" value-field="id" text-field="code" :options="noteTypeOption" @change="noteTypeChange()" />
                            </b-col>
                            <b-col class="col-lg-4 mt-1">
                                <label for="invoice-financial-ref-input">{{ $t('invoice.financial_ref') }}</label>
                                <b-form-input disabled id="invoice-financial-ref-input" :placeholder=" $t('invoice.financial_ref')" size="sm" v-model="financialRef" />
                            </b-col>
                        </b-row>
                        <b-row>
                            <!-- Sol kısım devam eden form alanları -->
                            <b-col class="col-lg-12 mt-1">
                                <label for="invoice-invoice-note-input">{{ $t('invoice.note_type') }}</label>
                                <b-form-textarea disabled id="invoice-invoice-note-input" :placeholder=" $t('invoice.note_type')" size="sm" rows="4" v-model="invoiceNote" />
                            </b-col>
                        </b-row>
                    </div>
                    <!-- Sağ kısım -->
                    <div class="col-lg-7">
                        <b-row>
                            <!-- Sağ kısım devam eden form alanları -->
                            <b-col class="col-lg-12">
                                <b-row>
                                    <b-col class="col-3">
                                        <label class="mt-2" for="invoice-dailywork-input">{{ $t('invoice.invoice_item') }}</label>
                                    </b-col>
                                    <b-col class="col-9" style="text-align:right;">
                                        <b-button variant="link" @click="showAddDailyworkForm()">{{ $t('invoice.dailywork_add') }}</b-button>
                                        <b-button variant="link" @click="showAddItemForm()">{{ $t('invoice.add_item') }}</b-button>
                                        <b-button variant="link" @click="showDiscountForm()">{{ $t('invoice.apply_discount') }}</b-button>
                                    </b-col>
                                </b-row>
                                <draggable :disabled="invoiceStatusId > 1" v-model="invoiceDetailList" tag="ul" class="list-group list-group-flush cursor-move">
                                    <b-list-group-item v-for="(item, index) in invoiceDetailList" :key="index" tag="li">
                                        <b-row>
                                            <b-col class="col-1">
                                                <span>{{ item.recordNo }}</span>
                                            </b-col>
                                            <b-col class="col-6">
                                                <span> {{ item.name }} </span>

                                                <p v-if="item.ledesDetail.length > 5">
                                                    <br />
                                                    <span>{{ item.ledesDetail }}</span>

                                                </p>

                                            </b-col>
                                            <b-col class="col-2" style="text-align:right;">
                                                <span>{{ item.itemDefination}} </span>
                                                <span>{{ currency}} </span>
                                            </b-col>
                                            <b-col class="col-3">
                                                <b-button v-b-tooltip.html :title="$t('others.edit')" v-b-modal.modal-top variant="primary" class="btn-icon mb-1 ml-05" size="sm" @click="showUpdateItemForm(item.invoiceDetailId)">
                                                    <feather-icon icon="EditIcon" />
                                                </b-button>
                                                <b-button v-b-tooltip.html :title="$t('others.delete')" variant="warning" class="btn-icon mb-1 ml-05" size="sm" @click="deletedInvoiceDetail(item.invoiceDetailId)">
                                                    <feather-icon icon="XIcon" />
                                                </b-button>
                                                <b-button v-b-tooltip.html :title="$t('others.copy')" variant="success" class="btn-icon mb-1 ml-05" size="sm" @click="copyText(item.name)">
                                                    <feather-icon icon="CopyIcon" />
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-list-group-item>

                                </draggable>
                                <b-list-group-item style="border:none;">
                                    <b-row>
                                        <b-col class="col-2"></b-col>
                                        <b-col class="col-7" style="text-align:right;"> {{ $t('invoice.total') }} : </b-col>
                                        <b-col class="col-2" style="text-align:right;">
                                            <span v-if="this.invoiceDetailList != null" style="color: red; font-weight: bold">
                                                {{ $formatMoney(this.invoiceDetailList.reduce((n, {price}) => n + price, 0)) }}
                                            </span>
                                            <span style="color: red; font-weight: bold;">{{ currency}} </span>
                                        </b-col>
                                        <b-col class="col-1"></b-col>
                                    </b-row>
                                </b-list-group-item>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </b-form-group>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getInvoiceList: {
            type: Function
        },
        getDailyworkList: {
            type: Function
        }
    },
    data() {
        return {
            invoiceData: null,
            id: 0,
            debitNo: '',
            clientAccountingId: 0,
            clientId: 0,
            agentSelect: 0,
            agentOption: [],
            billingStatusSelect: 0,
            billingStatusOption: [],
            proformaDate: null,
            agentReferenceNo: '',
            stockReferenceNo: '',
            invoiceStatusId: 0,
            topic: '',
            noteTypeOption: [],
            noteTypeSelect: 0,
            proformaTypeOption: [],
            proformaTypeSelect: 0,
            header: '',
            currency: '',
            clientAccountingCode: '',
            clientName: '',
            invoiceNote: '',
            financialRef: '',
            isPaid: true,
            currencyId: 0,
            columns: [{
                    label: this.$t('multiDailyworkSave.name'),
                    field: `recordNo`,
                },
                {
                    field: `name`,
                },
                {
                    field: `price`,
                }
            ],
            fields: [{
                    key: 'recordNo',
                    label: 'Record No',
                    sortable: true
                },
                {
                    key: 'name',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'price',
                    label: 'Price',
                    sortable: true
                },
            ],
            invoiceDetailList: [],
            accountingNote: '',
            clientNote: '',
            clientNoteType: '',
        }
    },
    methods: {
        copyText(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.$CopiedAlert()
            }).catch(err => {
                this.$WarningAlert()
            });
        },
        setClientId(event) {
            if (event != null) {
                this.clientId = event.clientId
            } else {
                this.clientId = null;
            }
        },
        onSearch(search, loading) {
            if (search.length) {
                loading(true);
                this.search(loading, search, this);
            }
        },
        search(loading, search, vm) {
            if (search.length > 2) {
                const data = {
                    clientAccountingId: 0,
                    clientName: search
                }
                const controller = {
                    name: 'ClientAccounting',
                    actionName: 'GetInvoiceClientAccountingByClientName'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    this.agentOption = response.data.clientList
                    loading(false)
                })
            }
        },
        noteTypeChange() {
            if (this.noteTypeOption != null && this.noteTypeSelect > 0) {
                this.invoiceNote = this.noteTypeOption.find(p => p.id == this.noteTypeSelect).note
            }
        },
        sendApproval() {
            this.$PrivateConfirm(this.$t("invoice.confirmTitle"), this.$t("invoice.confirmMessage"), "warning", this.$t("invoice.confirmButtonText"), this.$t("invoice.cancelButtonText")).then(result => {
                if (result.value) {
                    const data = {
                        id: this.id,
                        invoiceStatusId: 2
                    }
                    const controller = {
                        name: 'Invoice',
                        actionName: 'ChangeInvoiceStatus'
                    }
                    const payload = {
                        data: data,
                        controller: controller
                    }
                    this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            if (this.getInvoiceList != null) {
                                this.getInvoiceList()
                            }
                            if (this.getDailyworkList != null) {
                                this.getDailyworkList()
                            }
                            this.showInfo(this.id)
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        async getReminderInfo() {
            this.$refs.reminderForm.showInfo(0, this.id, 20)
        },
        showInfo(id) {
            this.$refs['invoiceFormModal'].show()
            this.invoiceDetailList = []

            this.accountingNote = ''
            this.clientNote = ''

            this.id = id
            const data = {
                id: this.id
            }
            const controller = {
                name: 'Invoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.invoiceData = response.data
                    this.invoiceStatusId = response.data.invoice.invoiceStatusId

                    this.debitNo = response.data.invoice.debitNo
                    this.proformaDate = response.data.invoice.proformaDate
                    this.financialRef = response.data.invoice.financialRef
                    this.agentReferenceNo = response.data.invoice.agentReferenceNo
                    this.stockReferenceNo = response.data.invoice.stockReferenceNo
                    this.header = response.data.invoice.header
                    this.noteTypeSelect = response.data.invoice.noteToBeOnInvoiceId
                    this.noteTypeOption = response.data.noteToBeOnInvoiceList
                    this.noteTypeOption.splice(0, 0, this.$nullSelected('code'))
                    this.noteTypeChange()

                    this.billingStatusSelect = response.data.invoice.billingStatusId
                    this.billingStatusOption = response.data.billingStatusList
                    this.billingStatusOption.splice(0, 0, this.$nullSelected())
                    this.isPaid = response.data.invoice.isPaid
                    this.proformaTypeSelect = response.data.invoice.proformaTypeId
                    this.proformaTypeOption = response.data.proformaTypeList

                    this.clientName = response.data.invoice.client
                    this.currency = response.data.invoice.currencyCode
                    this.currencyId = response.data.invoice.currencyId

                    if (response.data.invoiceDetailList != null) {
                        this.invoiceDetailList = response.data.invoiceDetailList
                    }
                    this.clientAccountingId = response.data.invoice.clientAccountingId
                    if (response.data.invoice.clientAccountingId > 0) {
                        const clientAccountingData = {
                            clientAccountingId: response.data.invoice.clientAccountingId
                        }
                        const clientAccountingController = {
                            name: 'ClientAccounting',
                            actionName: 'GetClientAccountingByClientName'
                        }
                        const clientAccountingPayload = {
                            data: clientAccountingData,
                            controller: clientAccountingController
                        }
                        this.$store.dispatch('moduleAuth/customService', clientAccountingPayload).then((clientAccountingResponse) => {
                            this.agentOption = clientAccountingResponse.data.clientList
                            this.agentSelect = clientAccountingResponse.data.clientList[0]
                            this.clientId = clientAccountingResponse.data.clientList[0].clientId
                        })
                    } else {
                        this.agentOption = []
                        this.agentSelect = 0;
                    }
                } else {
                    this.$bvModal.hide('invoiceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        showAddDailyworkForm() {
            this.$refs.invoiceAddDailyworkForm.showInfo(this.id, this.clientAccountingId)
        },
        showInfoFromDailyWorkList(dailyWorkList) {
            this.accountingNote = ''
            this.clientNote = ''

            this.id = 0
            const data = {
                employeeId: localStorage.getItem('EmployeeId'),
                invoiceDetailList: dailyWorkList
            }
            const controller = {
                name: 'Invoice',
                actionName: 'GetInfoFromDailyWorkList'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    if (this.getInvoiceList != null) {
                        this.getInvoiceList()
                    }
                    if (this.getDailyworkList != null) {
                        this.getDailyworkList()
                    }
                    this.showInfo(response.data.invoiceId)
                } else {
                    this.$bvModal.hide('invoiceFormModal')
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        addOrUpdate() {
            if (this.invoiceDetailList.length == 0) {
                this.$ErrorSaveAlert()
                return
            }

            const data = {
                invoiceDto: {
                    id: this.id,
                    proformaDate: this.proformaDate,
                    employeeId: localStorage.getItem('EmployeeId'),
                    clientAccountingId: this.clientAccountingId,
                    header: this.header,
                    agentReferenceNo: this.agentReferenceNo,
                    stockReferenceNo: this.stockReferenceNo,
                    noteToBeOnInvoiceId: this.noteTypeSelect,
                    billingStatusId: this.billingStatusSelect,
                    proformaTypeId: this.proformaTypeSelect,
                    financialRef: this.financialRef
                },
                invoiceDetailList: this.invoiceDetailList,
            }
            const controller = {
                name: 'Invoice'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.id = response.data.invoiceId
                            this.debitNo = response.data.debitNo
                            this.showInfo(response.data.invoiceId)
                            //this.$bvModal.hide('invoiceFormModal')
                            if (this.getInvoiceList != null) {
                                this.getInvoiceList()
                            }
                            if (this.getDailyworkList != null) {
                                this.getDailyworkList()
                            }
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        async showDiscountForm() {
            this.$refs.invoiceDiscountForm.showInfo(this.id, this.currencyId, this.invoiceData.invoiceDetailList.length)
        },
        async showAddItemForm() {
            this.$refs.invoiceAddItemForm.showInfo(this.id, this.currencyId, this.invoiceData.invoiceDetailList.length)
        },
        async showUpdateItemForm(detailId) {
            this.$refs.invoiceUpdateItemForm.showInfo(detailId, this.id)
        },
        async downloadInvoicePdf() {
            const data = {
                InvoiceId: this.id,
            }
            const controller = {
                name: 'Invoice',
                actionName: 'DownloadProforma'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile(response.data.document.name, response.data.base64, response.data.document.type)
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        async deletedInvoiceDetail(id) {
            const data = {
                id: id
            }
            const controller = {
                name: 'InvoiceDetail'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$DeleteConfirm().then(result => {
                if (result.value) {
                    this.$store.dispatch('moduleAuth/delete', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$DeleteAlert()
                            this.showInfo(this.id)
                        }

                        if (this.getInvoiceList != null) {
                            this.getInvoiceList()
                        }
                        if (this.getDailyworkList != null) {
                            this.getDailyworkList()
                        }

                    })
                }
            })
        },
    }
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}

.datepickerStyle {
    max-height: 2.2em;
    font-family: fangsong;
}
</style>
