<template>
<div>
    <!-- modal -->
    <b-modal id="trademarkOppositionBasisForOppositionFormModal" centered size="lg" no-close-on-backdrop ref="trademarkOppositionBasisForOppositionFormModal" :title=" $t('trademarkOppositionBasisForOpposition.opposition_basis_info')" :cancel-title=" $t('others.cancel_title')" :ok-title=" $t('others.ok_title')" cancel-variant="outline-secondary" @ok.prevent="addOrUpdate" @close="clear()">
        <validation-observer ref="infoRules" tag="form">
            <b-row>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <b-form-group :label=" $t('trademarkOppositionBasisForOpposition.trademark_name_search')" label-for="trademarkOppositionBasisForOppositionTrademark">
                            <v-select id="trademarkOppositionBasisForOppositionTrademark" v-model="trademarkSelect" :options="trademarkOption" label="name" class="w-100" @input="setInfo">
                                <!-- @search="onSearch"  -->
                            </v-select>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="trademarkOppositionBasisForOpposition-Name-input">{{ $t('trademarkOppositionBasisForOpposition.name') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('trademarkOppositionBasisForOpposition.name')" rules="required">
                                <b-form-input id="trademarkOppositionBasisForOpposition-Name-input" v-model="trademarkOppositionBasisForOppositionName" maxlength="50" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
                <b-col class="col-md-12 mt-1">
                    <b-form>
                        <label for="trademarkOppositionBasisForOpposition-Application-No-input">{{ $t('trademarkOppositionBasisForOpposition.application_no') }}</label>
                        <b-form-group>
                            <validation-provider #default="{ errors }" :name=" $t('trademarkOppositionBasisForOpposition.application_no')" rules="required">
                                <b-form-input id="trademarkOppositionBasisForOpposition-Application-No-input" v-model="applicationNo" maxlength="12" :state="errors.length > 0 ? false:null" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-form>
                </b-col>
            </b-row>
        </validation-observer>
    </b-modal>
</div>
</template>

<script>
export default {
    props: {
        getTrademarkOppositionBasisForOppositionList: {
            type: Function
        },
        getTrademarkOppositionList: {
            type: Function
        },
    },
    data() {
        return {
            id: 0,
            trademarkOppositionId: 0,
            trademarkOppositionBasisForOppositionName: '',
            applicationNo: '',
            trademarkSelect: '',
            trademarkOption: [],
            name: '',
            trademarkId: 0,
            clientAccountingId: 0
        }
    },
    methods: {
        setInfo(event) {
            this.trademarkId = event.Id
            this.applicationNo = event.applicationNo
            this.trademarkOppositionBasisForOppositionName = event.name
        },
        // onSearch(search, loading) {
        //     if (search.length) {
        //         loading(true);
        //         this.search(loading, search, this);
        //     }
        // },
        showInfo(id, trademarkOppositionId, clientAccountingId) {
            this.$refs['trademarkOppositionBasisForOppositionFormModal'].show()
            this.id = id
            this.trademarkOppositionId = trademarkOppositionId
            this.clientAccountingId = clientAccountingId
            const data = {
                id: this.id
            }
            const controller = {
                name: 'TrademarkOppositionBasisForOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$store.dispatch('moduleAuth/getInfo', payload).then((response) => {
                this.trademarkOppositionBasisForOppositionName = response.data.trademarkOppositionBasisForOpposition.name
                this.applicationNo = response.data.trademarkOppositionBasisForOpposition.applicationNo
                this.trademarkId = response.data.trademarkOppositionBasisForOpposition.trademarkId
            })
            if (this.clientAccountingId > 0) {
                const data = {
                    id: this.clientAccountingId
                }
                const controller = {
                    name: 'Trademark',
                    actionName: 'GetTrademarkListByClientAccountingId'
                }
                const payload = {
                    data: data,
                    controller: controller
                }
                this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                    if (response.data.resultStatus == true) {
                        this.trademarkOption = response.data.trademarkListByClientAccountingId
                        this.employeeOption.splice(0, 0, this.$nullSelected())
                    } 
                })
            }
        },
        // search(loading, search) {
        //     if (search.length > 2) {
        //         const data = {
        //             string: search
        //         }
        //         const controller = {
        //             name: 'Trademark',
        //             actionName: 'GetTrademarkListByTrademarkName'
        //         }
        //         const payload = {
        //             data: data,
        //             controller: controller
        //         }
        //         this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
        //             this.trademarkOption = response.data.trademarkList
        //             loading(false)
        //         })
        //     }
        // },
        addOrUpdate() {
            const data = {
                id: this.id,
                trademarkOppositionId: this.trademarkOppositionId,
                name: this.trademarkSelect.value,
                applicationNo: this.applicationNo,
                trademarkId: this.trademarkSelect
            }
            const controller = {
                name: 'TrademarkOppositionBasisForOpposition'
            }
            const payload = {
                data: data,
                controller: controller
            }
            this.$refs.infoRules.validate().then(success => {
                if (success) {
                    this.$store.dispatch('moduleAuth/save', payload).then((response) => {
                        if (response.data.resultStatus == true) {
                            this.$bvModal.hide('trademarkOppositionBasisForOppositionFormModal')
                            this.trademarkOppositionBasisForOppositionName = ''
                            this.trademarkSelect = ''
                            this.applicationNo = ''
                            this.getTrademarkOppositionBasisForOppositionList()
                            this.getTrademarkOppositionList()
                            this.$SaveAlert()
                        } else {
                            this.$WarningAlert(response.data.resultMessage)
                        }
                    })
                }
            })
        },
        clear() {
            this.trademarkOppositionBasisForOppositionName = null
        }
    },
}
</script>

<style lang="scss">
[dir=ltr] .modal-header .close {
    margin: -0.4rem -0.4rem -0.8rem auto;
}

[dir] label {
    margin-bottom: 0.2857rem;
    font-weight: bold;
}

.modal .modal-content {
    overflow: visible;
    margin-bottom: auto;
}

[dir] .invalid-tooltip {
    padding: 0.4rem 0.775rem !important;
    margin-top: -1.5rem !important;
    background-color: #ea5455 !important;
    border-radius: 0.358rem !important;
    left: 20px !important
}

[dir] .vs__selected-options {
    padding: 0 2px;
    font-size: 0.9em;
}
</style>
