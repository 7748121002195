import Vue from "vue";
import CustomPlugin from "@core/custom/custom";
import FTPClientPlugin from "@core/components/ftp-client/FTPClient";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import VueExcelXlsx from "vue-excel-xlsx";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import excel from "vue-excel-export";
import { BAlert, BPagination, BFormGroup, BDropdownItem, BDropdown, BFormInput, BInputGroupPrepend, BFormSelect, BFormSelectOption, BRow, BCol, VBTooltip,
  BButton, BCardText, BCard, BCardBody, BLink, BImg, BCardTitle, BInputGroup, BInputGroupAppend, BFormCheckbox, BFormRadioGroup, BFormRadio, BForm, BFormTextarea,
  BFormFile, BBadge, BTable, BFormTimepicker, BMedia, BFormDatepicker, BPopover, VBPopover, BMediaAside, BMediaBody, BTabs, BTab, BCardHeader, BAvatar,
  BFormCheckboxGroup, BFormInvalidFeedback, BFormValidFeedback, BTableSimple, BThead, BTr, BTh, BTd, BTbody, BTfoot, BListGroup, BListGroupItem,
  BDropdownForm, BJumbotron} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueSweetalert2 from "vue-sweetalert2";
import { localize } from "vee-validate";
import { required } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import i18n from "@/libs/i18n";
import vSelect from "vue-select";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Cleave from "vue-cleave-component";
import Vueditor from "vueditor";
import flatPickr from "vue-flatpickr-component";
import { Turkish } from "flatpickr/dist/l10n/tr.js";
import FlipCountdown from "vue2-flip-countdown";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import VueApexCharts from "vue-apexcharts";
import AwesomeMask from "awesome-mask";
import draggable from "vuedraggable";

//Pages
import CustomDatePicker from "@core/components/CustomDatePicker.vue";
import departmentForm from "@/@modules/Forms/Departments/DepartmentForm.vue";
import DepartmentList from "@/@modules/Lists/Departments/DepartmentList.vue";
import MailAccountForm from "@/@modules/Forms/MailAccount/MailAccountForm.vue";
import MailAccountList from "@/@modules/Lists/MailAccount/MailAccountList.vue";
import MailTemplateForm from "@/@modules/Forms/MailTemplate/MailTemplateForm.vue";
import MailTemplateList from "@/@modules/Lists/MailTemplate/MailTemplateList.vue";
import PermissionGroupForm from "@/@modules/Forms/PermissionGroup/PermissionGroupForm.vue";
import PermissionGroupList from "@/@modules/Lists/PermissionGroup/PermissionGroupList.vue";
import PermissionTemplateList from "@/@modules/Lists/PermissionTemplate/PermissionTemplateList.vue";
import PermissionTemplateForm from "@/@modules/Forms/PermissionTemplate/PermissionTemplateForm.vue";
import PermissionForm from "@/@modules/Forms/Permission/PermissionForm.vue";
import PermissionList from "@/@modules/Lists/Permission/PermissionList.vue";
import CountryList from "@/@modules/Lists/Country/CountryList.vue";
import CountryForm from "@/@modules/Forms/Country/CountryForm.vue";
import EmployeeClassificationForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeClassificationForm.vue";
import EmployeeClassificationList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeClassificationList.vue";
import EmployeeList from "@/@modules/Lists/HumanResources/EmployeeList.vue";
import MyAccountGeneral from "../src/views/pages/MyAccount/MyAccountGeneral.vue";
import MyAccountPassword from "../src/views/pages/MyAccount/MyAccountPassword.vue";
import MyAccountForm from "@/@modules/Forms/MyAccount/MyAccountForm.vue";
import EmployeePriceForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeePriceForm.vue";
import EmployeeForm from "@/@modules/Forms/HumanResources/EmployeeForm.vue";
import EmployeePhotoCropperForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeePhotoCropperForm.vue";
import EmployeeNoteForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeNoteForm";
import ChangePassword from "../src/views/ChangePassword.vue";
import WordTemplatesForm from "@/@modules/Forms/WordTemplates/WordTemplatesForm.vue";
import WordTemplatesList from "@/@modules/Lists/WordTemplates/WordTemplatesList.vue";
import ClientForm from "@/@modules/Forms/Client/ClientForm.vue";
import ClientList from "@/@modules/Lists/Client/ClientList.vue";
import ClientAddressList from "@/@modules/Lists/Client/ClientSubTables/ClientAddressList.vue";
import ClientAddressForm from "@/@modules/Forms/Client/ClientSubForms/ClientAddressForm.vue";
import ClientContactList from "@/@modules/Lists/Client/ClientSubTables/ClientContactList.vue";
import ClientContactForm from "@/@modules/Forms/Client/ClientSubForms/ClientContactForm.vue";
import ClientResponsibleList from "@/@modules/Lists/Client/ClientSubTables/ClientResponsibleList.vue";
import ClientResponsibleForm from "@/@modules/Forms/Client/ClientSubForms/ClientResponsibleForm.vue";
import DocumentsList from "@/@modules/Lists/Documents/DocumentsList.vue";
import DocumentsForm from "@/@modules/Forms/Documents/DocumentsForm.vue";
import ClientAccountingList from "@/@modules/Lists/Client/ClientSubTables/ClientAccountingList.vue";
import ClientAccountingForm from "@/@modules/Forms/Client/ClientSubForms/ClientAccountingForm.vue";
import ClientHistoryList from "@/@modules/Lists/Client/ClientSubTables/ClientHistoryList.vue";
import ClientMergeCompanyForm from "@/@modules/Forms/Client/ClientSubForms/ClientMergeCompanyForm.vue";
import ClientLiableForm from "@/@modules/Forms/Client/ClientSubForms/ClientLiableForm.vue";
import ClientLiableList from "@/@modules/Lists/Client/ClientSubTables/ClientLiableList.vue";
import ClientConflictForm from "@/@modules/Forms/Client/ClientSubForms/ClientConflictForm.vue";
import ClientConflictList from "@/@modules/Lists/Client/ClientSubTables/ClientConflictList.vue";
import ContactTypeList from "@/@modules/Lists/ContactType/ContactTypeList";
import ContactTypeForm from "@/@modules/Forms/ContactType/ContactTypeForm";
import MailContactTypeForm from "@/@modules/Forms/MailContactType/MailContactTypeForm";
import MailContactTypeList from "@/@modules/Lists/MailContactType/MailContactTypeList";
import ClientNoteForm from "@/@modules/Forms/Client/ClientSubForms/ClientNoteForm";
import ClientNoteList from "@/@modules/Lists/Client/ClientSubTables/ClientNoteList";
import InvoiceSendTypeList from "@/@modules/Lists/InvoiceSendType/InvoiceSendTypeList";
import InvoiceSendTypeForm from "@/@modules/Forms/InvoiceSendType/InvoiceSendTypeForm";
import ResponsibleFieldList from "@/@modules/Lists/ResponsibleField/ResponsibleFieldList";
import ResponsibleFieldForm from "@/@modules/Forms/ResponsibleField/ResponsibleFieldForm";
import PriceListsList from "@/@modules/Lists/PriceList/PriceListsList";
import PriceListsForm from "@/@modules/Forms/PriceList/PriceListsForm";
import DocumentTypeList from "@/@modules/Lists/DocumentType/DocumentTypeList";
import DocumentTypeForm from "@/@modules/Forms/DocumentType/DocumentTypeForm";
import DocumentTypeDetailForm from "@/@modules/Forms/DocumentType/DocumentTypeSubForm/DocumentTypeDetailForm";
import DocumentTypeDetailList from "@/@modules/Lists/DocumentType/DocumentTypeSubList/DocumentTypeDetailList";
import TitleList from "@/@modules/Lists/Title/TitleList";
import TitleForm from "@/@modules/Forms/Title/TitleForm";
import PositionForm from "@/@modules/Forms/Position/PositionForm";
import PositionList from "@/@modules/Lists/Position/PositionList";
import LiableFieldList from "@/@modules/Lists/LiableField/LiableFieldList";
import LiableFieldForm from "@/@modules/Forms/LiableField/LiableFieldForm";
import ClientResponsibleReportList from "@/@modules/Lists/Client/ClientReports/ClientResponsibleReportList";
import EmployeePriceList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeePriceList";
import EmployeeNoteList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeNoteList";
import EmployeePermissionTableForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeePermissionTableForm";
import SectorForm from "@/@modules/Forms/Sector/SectorForm";
import SectorList from "@/@modules/Lists/Sector/SectorList";
import DomainList from "@/@modules/Lists/Domain/DomainList.vue";
import DomainForm from "@/@modules/Forms/Domain/DomainForm.vue";
import DomainOwnerForm from "@/@modules/Forms/Domain/DomainSubForms/DomainOwnerForm";
import DomainOwnerList from "@/@modules/Lists/Domain/DomainSubTables/DomainOwnerList";
import DomainResponsibleForm from "@/@modules/Forms/Domain/DomainSubForms/DomainResponsibleForm";
import DomainResponsibleList from "@/@modules/Lists/Domain/DomainSubTables/DomainResponsibleList";
import DomainOppositionList from "@/@modules/Lists/DomainOpposition/DomainOppositionList";
import DomainOppositionForm from "@/@modules/Forms/DomainOpposition/DomainOppositionForm";
import DomainOppositionOwnerForm from "@/@modules/Forms/DomainOpposition/DomainOppositionSubForms/DomainOppositionOwnerForm";
import DomainOppositionResponsibleForm from "@/@modules/Forms/DomainOpposition/DomainOppositionSubForms/DomainOppositionResponsibleForm";
import DomainOppositionOwnerList from "@/@modules/Lists/DomainOpposition/DomainOppositionSubTables/DomainOppositionOwnerList";
import DomainOppositionResponsibleList from "@/@modules/Lists/DomainOpposition/DomainOppositionSubTables/DomainOppositionResponsibleList";
import DomainOppositionBasisForOppositionList from "@/@modules/Lists/DomainOpposition/DomainOppositionSubTables/DomainOppositionBasisForOppositionList";
import DomainOppositionBasisForOppositionForm from "@/@modules/Forms/DomainOpposition/DomainOppositionSubForms/DomainOppositionBasisForOppositionForm";
import DesignList from "@/@modules/Lists/Design/DesignList";
import DesignOwnerList from "@/@modules/Lists/Design/DesignSubTables/DesignOwnerList";
import DesignResponsibleList from "@/@modules/Lists/Design/DesignSubTables/DesignResponsibleList";
import DesignForm from "@/@modules/Forms/Design/DesignForm";
import DesignOwnerForm from "@/@modules/Forms/Design/DesignSubForm/DesignOwnerForm";
import DesignResponsibleForm from "@/@modules/Forms/Design/DesignSubForm/DesignResponsibleForm";
import DesignPriorityList from "@/@modules/Lists/Design/DesignSubTables/DesignPriorityList";
import DesignPriorityForm from "@/@modules/Forms/Design/DesignSubForm/DesignPriorityForm";
import DesignDesignerForm from "@/@modules/Forms/Design/DesignSubForm/DesignDesignerForm";
import DesignDesignerList from "@/@modules/Lists/Design/DesignSubTables/DesignDesignerList";
import DesignRenewalForm from "@/@modules/Forms/Design/DesignSubForm/DesignRenewalForm";
import DesignProductForm from "@/@modules/Forms/Design/DesignSubForm/DesignProductForm.vue";
import DesignRenewalList from "@/@modules/Lists/Design/DesignSubTables/DesignRenewalList";
import DesignProductList from "@/@modules/Lists/Design/DesignSubTables/DesignProductList.vue";
import DesignObjectionToUsList from "@/@modules/Lists/Design/DesignSubTables/DesignObjectionToUsList.vue";
import DesignObjectionToUsForm from "@/@modules/Forms/Design/DesignSubForm/DesignObjectionToUsForm.vue";
import AgentNoteForm from "@/@modules/Forms/Note/AgentNoteForm.vue";
import OwnerNoteForm from "@/@modules/Forms/Note/OwnerNoteForm.vue";
import DesignOppositionList from "@/@modules/Lists/DesignOpposition/DesignOppositionList";
import DesignOppositionForm from "@/@modules/Forms/DesignOpposition/DesignOppositionForm";
import DesignOppositionOwnerForm from "@/@modules/Forms/DesignOpposition/DesignOppositionSubForms/DesignOppositionOwnerForm";
import DesignOppositionOwnerList from "@/@modules/Lists/DesignOpposition/DesignOppositionSubTables/DesignOppositionOwnerList";
import DesignOppositionResponsibleForm from "@/@modules/Forms/DesignOpposition/DesignOppositionSubForms/DesignOppositionResponsibleForm";
import DesignOppositionResponsibleList from "@/@modules/Lists/DesignOpposition/DesignOppositionSubTables/DesignOppositionResponsibleList";
import DesignOppositionBasisForOppositionList from "@/@modules/Lists/DesignOpposition/DesignOppositionSubTables/DesignOppositionBasisForOppositionList";
import DesignOppositionBasisForOppositionForm from "@/@modules/Forms/DesignOpposition/DesignOppositionSubForms/DesignOppositionBasisForOppositionForm";
import PatentList from "@/@modules/Lists/Patent/PatentList";
import PatentForm from "@/@modules/Forms/Patent/PatentForm";
import PatentPriorityList from "@/@modules/Lists/Patent/PatentSubTables/PatentPriorityList";
import PatentPriorityForm from "@/@modules/Forms/Patent/PatentSubForms/PatentPriorityForm";
import PatentInventorList from "@/@modules/Lists/Patent/PatentSubTables/PatentInventorList";
import PatentInventorForm from "@/@modules/Forms/Patent/PatentSubForms/PatentInventorForm";
import PatentOwnerList from "@/@modules/Lists/Patent/PatentSubTables/PatentOwnerList";
import PatentOwnerForm from "@/@modules/Forms/Patent/PatentSubForms/PatentOwnerForm";
import PatentResponsibleList from "@/@modules/Lists/Patent/PatentSubTables/PatentResponsibleList";
import PatentResponsibleForm from "@/@modules/Forms/Patent/PatentSubForms/PatentResponsibleForm";
import TrademarkList from "@/@modules/Lists/Trademark/TrademarkList";
import TrademarkOwnerList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkOwnerList";
import TrademarkResponsibleList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkResponsibleList";
import TrademarkPriorityList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkPriorityList";
import TrademarkForm from "@/@modules/Forms/Trademark/TrademarkForm";
import TrademarkResponsibleForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkResponsibleForm";
import TrademarkOwnerForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkOwnerForm";
import TrademarkPriorityForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkPriorityForm";
import TrademarkViennaClassesForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkViennaClassesForm";
import TrademarkViennaClassesList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkViennaClassesList";
import TrademarkNiceClassForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkNiceClassForm";
import TrademarkNiceClassList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkNiceClassList";
import HolidayForm from "@/@modules/Forms/Holiday/HolidayForm";
import HolidayList from "@/@modules/Lists/Holiday/HolidayList";
import DesignDeadlineReportList from "@/@modules/Lists/Design/DesignDeadlineReportList";
import DesignBulletinCheckReportList from "@/@modules/Lists/BulletinReport/DesignBulletinCheckReportList";
import DesignBulletinCheckReportForm from "@/@modules/Forms/BulletinReport/DesignBulletinCheckReportForm";
import PatentBulletinCheckList from "@/@modules/Lists/BulletinReport/PatentBulletinCheckList";
import PatentBulletinCheckForm from "@/@modules/Forms/BulletinReport/PatentBulletinCheckForm";
import TranslatorList from "@/@modules/Lists/Translator/TranslatorList";
import TranslatorPriceList from "@/@modules/Lists/Translator/TranslatorSubTables/TranslatorPriceList";
import TranslatorContactInformationList from "@/@modules/Lists/Translator/TranslatorSubTables/TranslatorContactInformationList";
import TranslatorForm from "@/@modules/Forms/Translator/TranslatorForm";
import TranslatorPriceForm from "@/@modules/Forms/Translator/TranslatorSubForms/TranslatorPriceForm";
import TranslatorContactInformationForm from "@/@modules/Forms/Translator/TranslatorSubForms/TranslatorContactInformationForm";
import PatentStatusList from "@/@modules/Lists/PatentStatus/PatentStatusList";
import PatentStatusForm from "@/@modules/Forms/PatentStatus/PatentStatusForm";
import PatentHistoryForm from "@/@modules/Forms/Patent/PatentSubForms/PatentHistoryForm";
import PatentHistoryList from "@/@modules/Lists/Patent/PatentSubTables/PatentHistoryList";
import AnnualLeaveTypeForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/AnnualLeaveTypeForm";
import AnnualLeaveTypeList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/AnnualLeaveTypeList";
import LanguageForm from "@/@modules/Forms/Language/LanguageForm";
import LanguageList from "@/@modules/Lists/Language/LanguageList";
import PatentUpdateFieldsForm from "@/@modules/Forms/Patent/PatentReports/PatentUpdateFieldsForm";
import PatentUpdateFieldsList from "@/@modules/Lists/Patent/PatentReports/PatentUpdateFieldsList";
import MailSendForm from "@/@modules/Forms/MailSendForm/MailSendForm";
import PatentXmlUploadForm from "@/@modules/Forms/Patent/PatentSubForms/PatentXmlUploadForm";
import PatentDivisionForm from "@/@modules/Forms/Patent/PatentSubForms/PatentDivisionForm";
import PatentDivisionList from "@/@modules/Lists/Patent/PatentSubTables/PatentDivisionList";
import PatentTranslateForm from "@/@modules/Forms/Patent/PatentSubForms/PatentTranslateForm";
import PatentTranslateList from "@/@modules/Lists/Patent/PatentSubTables/PatentTranslateList";
import TranslateReportList from "@/@modules/Lists/Translator/TranslatorSubTables/TranslateReportList";
import PatentTradosUploadForm from "@/@modules/Forms/Patent/PatentSubForms/PatentTradosUploadForm";
import BulletinUploadForm from "@/@modules/Forms/Tpmk/BulletinUpload/BulletinUploadForm";
import LexologyList from "@/@modules/Lists/ExternalSources/LexologyList";
import LexologyForm from "@/@modules/Forms/ExternalSources/LexologyForm";
import Login from "./views/Login.vue";
import LoginModalForm from "./layouts/components/LoginModalForm.vue";
import BaseTimer from "@/layouts/components/BaseTimer";
import DesignBulletinAnalyseReportList from "@/@modules/Lists/BulletinAnalyse/DesignBulletinAnalyseReportList";
import TrademarkBulletinAnalyseReportList from "@/@modules/Lists/BulletinAnalyse/TrademarkBulletinAnalyseReportList";
import PatentBulletinAnalyseReportList from "@/@modules/Lists/BulletinAnalyse/PatentBulletinAnalyseReportList";
import ServiceTypeList from "@/@modules/Lists/Service/ServiceTypeList";
import ServiceList from "@/@modules/Lists/Service/ServiceList";
import ServiceTypeForm from "@/@modules/Forms/Service/ServiceTypeForm";
import ServiceForm from "@/@modules/Forms/Service/ServiceForm";
import PDFViewer from "@/@modules/Forms/PDFViewer/PDFViewer";
import DesignBulletinAnalyseReportForm from "@/@modules/Forms/BulletinAnalyse/DesignBulletinAnalyseReportForm";
import PriceListAgentsList from "@/@modules/Lists/PriceList/PriceListAgentsList";
import PriceListDetailList from "@/@modules/Lists/PriceList/PriceListDetailList";
import EpoXmlForm from "@/@modules/Forms/EpoXml/EpoXmlForm";
import PatentBulletinAnalyseReportForm from "@/@modules/Forms/BulletinAnalyse/PatentBulletinAnalyseReportForm";
import TrademarkBulletinAnalyseReportForm from "@/@modules/Forms/BulletinAnalyse/TrademarkBulletinAnalyseReportForm";
import ServiceFeeList from "@/@modules/Lists/Service/ServiceFeeList";
import AgentAnnuityPriceList from "@/@modules/Lists/AgentAnnuityPrice/AgentAnnuityPriceList";
import AgentAnnuityPriceForm from "@/@modules/Forms/AgentAnnuityPrice/AgentAnnuityPriceForm";
import AnnuityList from "@/@modules/Lists/Annuity/AnnuityList";
import AnnuityForm from "@/@modules/Forms/Annuity/AnnuityForm";
import AnnuityDetailList from "@/@modules/Lists/Annuity/AnnuityDetail/AnnuityDetailList";
import AnnuityDetailForm from "@/@modules/Forms/Annuity/AnnuityDetail/AnnuityDetailForm";
import AnnuityDetailListForInvoice from "@/@modules/Lists/Annuity/AnnuityDetail/AnnuityDetailListForInvoice";
import TpmkExcelSaveForm from "@/@modules/Forms/Annuity/AnnuityDetail/TpmkExcelSaveForm";
import AgentAnnuityPriceDetailForm from "@/@modules/Forms/AgentAnnuityPrice/AgentAnnuityPriceSubForms/AgentAnnuityPriceDetailForm";
import AgentAnnuityPriceDetailList from "@/@modules/Lists/AgentAnnuityPrice/AgentAnnuityPriceSubTables/AgentAnnuityPriceDetailList";
import AnnuityDetailStatusForm from "@/@modules/Forms/AnnuityDetailStatus/AnnuityDetailStatusForm";
import AnnuityDetailStatusList from "@/@modules/Lists/AnnuityDetailStatus/AnnuityDetailStatusList";
import DesignStatusList from "@/@modules/Lists/DesignStatus/DesignStatusList";
import DesignStatusForm from "@/@modules/Forms/DesignStatus/DesignStatusForm";
import NoteTypeList from "@/@modules/Lists/NoteType/NoteTypeList";
import NoteTypeForm from "@/@modules/Forms/NoteType/NoteTypeForm";
import PatentNoteTypeList from "@/@modules/Lists/PatentNoteType/PatentNoteTypeList";
import PatentNoteTypeForm from "@/@modules/Forms/PatentNoteType/PatentNoteTypeForm";
import PatentNoteList from "@/@modules/Lists/Patent/PatentSubTables/PatentNoteList";
import PatentNoteForm from "@/@modules/Forms/Patent/PatentSubForms/PatentNoteForm";
import PatentAnnuityStatusList from "@/@modules/Lists/PatentAnnuityStatus/PatentAnnuityStatusList";
import PatentAnnuityStatusForm from "@/@modules/Forms/PatentAnnuityStatus/PatentAnnuityStatusForm";
import PatentAnnuityWillPayList from "@/@modules/Lists/PatentAnnuityWillPay/PatentAnnuityWillPayList";
import PatentAnnuityWillPayForm from "@/@modules/Forms/PatentAnnuityWillPay/PatentAnnuityWillPayForm";
import PatentAnnuityForm from "@/@modules/Forms/Patent/PatentSubForms/PatentAnnuityForm";
import PatentAnnuityList from "@/@modules/Lists/Patent/PatentSubTables/PatentAnnuityList";
import PatentTransactionReport from "@/@modules/Lists/Patent/PatentReports/PatentTransactionReport";
import PatentTransactionReportDetail from "@/@modules/Lists/Patent/PatentReports/PatentTransactionReportDetail";
import TrademarkApplicationTypeForm from "@/@modules/Forms/TrademarkApplicationType/TrademarkApplicationTypeForm";
import TrademarkApplicationTypeList from "@/@modules/Lists/TrademarkApplicationType/TrademarkApplicationTypeList";
import TrademarkStatusForm from "@/@modules/Forms/TrademarkStatus/TrademarkStatusForm";
import TrademarkStatusList from "@/@modules/Lists/TrademarkStatus/TrademarkStatusList";
import TrademarkHistoryForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkHistoryForm";
import TrademarkHistoryList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkHistoryList";
import NiceClassTypeForm from "@/@modules/Forms/NiceClassType/NiceClassTypeForm";
import NiceClassTypeList from "@/@modules/Lists/NiceClassType/NiceClassTypeList";
import ReminderForm from "@/@modules/Forms/Reminder/ReminderForm";
import PatentBulletinReviewList from "@/@modules/Lists/Patent/PatentSubTables/PatentBulletinReviewList";
import TrademarkNoteForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkNoteForm";
import TrademarkSearchTypeList from "@/@modules/Lists/TrademarkSearchType/TrademarkSearchTypeList";
import TrademarkSearchTypeForm from "@/@modules/Forms/TrademarkSearchType/TrademarkSearchTypeForm";
import TrademarkSearchStatusList from "@/@modules/Lists/TrademarkSearchStatus/TrademarkSearchStatusList";
import TrademarkSearchStatusForm from "@/@modules/Forms/TrademarkSearchStatus/TrademarkSearchStatusForm";
import TrademarkNoteTypeList from "@/@modules/Lists/TrademarkNoteType/TrademarkNoteTypeList";
import TrademarkNoteTypeForm from "@/@modules/Forms/TrademarkNoteType/TrademarkNoteTypeForm";
import TrademarkNoteList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkNoteList";
import PatentCertificateList from "@/@modules/Lists/Patent/PatentReports/PatentCertificateList";
import TrademarkTypeList from "@/@modules/Lists/TrademarkType/TrademarkTypeList";
import TrademarkTypeForm from "@/@modules/Forms/TrademarkType/TrademarkTypeForm";
import TrademarkSearchDatabaseList from "@/@modules/Lists/TrademarkSearchDatabase/TrademarkSearchDatabaseList";
import TrademarkSearchDatabaseForm from "@/@modules/Forms/TrademarkSearchDatabase/TrademarkSearchDatabaseForm";
import TrademarkSearchSuccessRateList from "@/@modules/Lists/TrademarkSearchSuccessRate/TrademarkSearchSuccessRateList";
import TrademarkSearchSuccessRateForm from "@/@modules/Forms/TrademarkSearchSuccessRate/TrademarkSearchSuccessRateForm";
import TrademarkSearchList from "@/@modules/Lists/TrademarkSearch/TrademarkSearchList";
import TrademarkSearchForm from "@/@modules/Forms/TrademarkSearch/TrademarkSearchForm";
import NationalTrademarkSearchList from "@/@modules/Lists/NationalTrademarkSearch/NationalTrademarkSearchList";
import NationalTrademarkSearchForm from "@/@modules/Forms/NationalTrademarkSearch/NationalTrademarkSearchForm";
import TrademarkSearchOwnerList from "@/@modules/Lists/TrademarkSearch/TrademarkSearchSubTables/TrademarkSearchOwnerList";
import TrademarkSearchOwnerForm from "@/@modules/Forms/TrademarkSearch/TrademarkSearchSubForm/TrademarkSearchOwnerForm";
import NationalTrademarkSearchOwnerList from "@/@modules/Lists/NationalTrademarkSearch/NationalTrademarkSearchSubTables/NationalTrademarkSearchOwnerList";
import NationalTrademarkSearchOwnerForm from "@/@modules/Forms/NationalTrademarkSearch/NationalTrademarkSearchSubForm/NationalTrademarkSearchOwnerForm";
import TrademarkSearchHistoryList from "@/@modules/Lists/TrademarkSearch/TrademarkSearchSubTables/TrademarkSearchHistoryList";
import TrademarkSearchHistoryForm from "@/@modules/Forms/TrademarkSearch/TrademarkSearchSubForm/TrademarkSearchHistoryForm";
import NationalTrademarkSearchHistoryList from "@/@modules/Lists/NationalTrademarkSearch/NationalTrademarkSearchSubTables/NationalTrademarkSearchHistoryList";
import NationalTrademarkSearchHistoryForm from "@/@modules/Forms/NationalTrademarkSearch/NationalTrademarkSearchSubForm/NationalTrademarkSearchHistoryForm";
import TrademarkRenewalsList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkRenewalsList";
import TrademarkRenewalsForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkRenewalsForm";
import PatentWorkingReminderList from "@/@modules/Lists/Patent/PatentReports/PatentWorkingReminderList";
import PatentAnnualFeeReminderList from "@/@modules/Lists/Patent/PatentReports/PatentAnnualFeeReminderList";
import TrademarkOppositionForm from "@/@modules/Forms/TrademarkOpposition/TrademarkOppositionForm";
import TrademarkOppositionList from "@/@modules/Lists/TrademarkOpposition/TrademarkOppositionList";
import TrademarkOppositionOwnerForm from "@/@modules/Forms/TrademarkOpposition/TrademarkOppositionSubForms/TrademarkOppositionOwnerForm";
import TrademarkOppositionOwnerList from "@/@modules/Lists/TrademarkOpposition/TrademarkOppositionSubTables/TrademarkOppositionOwnerList";
import TrademarkOppositionResponsibleForm from "@/@modules/Forms/TrademarkOpposition/TrademarkOppositionSubForms/TrademarkOppositionResponsibleForm";
import TrademarkOppositionResponsibleList from "@/@modules/Lists/TrademarkOpposition/TrademarkOppositionSubTables/TrademarkOppositionResponsibleList";
import TrademarkOppositionBasisForOppositionForm from "@/@modules/Forms/TrademarkOpposition/TrademarkOppositionSubForms/TrademarkOppositionBasisForOppositionForm";
import TrademarkOppositionBasisForOppositionList from "@/@modules/Lists/TrademarkOpposition/TrademarkOppositionSubTables/TrademarkOppositionBasisForOppositionList";
import TrademarkTransactionReport from "@/@modules/Lists/Trademark/Reports/TrademarkTransactionReport";
import TrademarkTransactionReportDetail from "@/@modules/Lists/Trademark/Reports/TrademarkTransactionReportDetail";
import NationalTrademarkForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkForm";
import NationalTrademarkList from "@/@modules/Lists/NationalTrademark/NationalTrademarkList";
import NationalTrademarkNiceClassForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkNiceClassForm.vue";
import NationalTrademarkNiceClassList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkNiceClassList.vue";
import NationalTrademarkOwnerForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkOwnerForm.vue";
import NationalTrademarkOwnerList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkOwnerList.vue";
import NationalTrademarkRenewalsForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkRenewalsForm.vue";
import NationalTrademarkRenewalsList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkRenewalsList.vue";
import NationalTrademarkResponsibleForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkResponsibleForm.vue";
import NationalTrademarkResponsibleList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkResponsibleList.vue";
import NationalPatentList from "@/@modules/Lists/NationalPatent/NationalPatentList";
import NationalPatentForm from "@/@modules/Forms/NationalPatent/NationalPatentForm";
import NationalPatentAnnuityList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentAnnuityList";
import NationalPatentAnnuityForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentAnnuityForm";
import NationalPatentInventorList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentInventorList";
import NationalPatentInventorForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentInventorForm";
import NationalPatentOwnerList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentOwnerList";
import NationalPatentOwnerForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentOwnerForm";
import NationalPatentResponsibleList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentResponsibleList";
import NationalPatentResponsibleForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentResponsibleForm";
import NationalTrademarkHistoryForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkHistoryForm.vue";
import NationalTrademarkHistoryList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkHistoryList.vue";
import NationalTrademarkCountryList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkCountryList";
import NationalTrademarkCountryForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkCountryForm";
import NationalTrademarkCountryShowHistoryListForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkCountryShowHistoryListForm";
import TrademarkSearchResponsibleForm from "@/@modules/Forms/TrademarkSearch/TrademarkSearchSubForm/TrademarkSearchResponsibleForm";
import TrademarkSearchResponsibleList from "@/@modules/Lists/TrademarkSearch/TrademarkSearchSubTables/TrademarkSearchResponsibleList";
import NationalTrademarkSearchResponsibleForm from "@/@modules/Forms/NationalTrademarkSearch/NationalTrademarkSearchSubForm/NationalTrademarkSearchResponsibleForm";
import NationalTrademarkSearchResponsibleList from "@/@modules/Lists/NationalTrademarkSearch/NationalTrademarkSearchSubTables/NationalTrademarkSearchResponsibleList";
import JobStatusList from "@/@modules/Lists/Job/JobSubList/JobStatusList";
import JobStatusForm from "@/@modules/Forms/Job/JobSubForm/JobStatusForm";
import NationalTrademarkApplicationTypeForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkApplicationTypeForm";
import NationalTrademarkApplicationTypeList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkApplicationTypeList";
import NationalPatentHistoryForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentHistoryForm.vue";
import NationalPatentHistoryList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentHistoryList.vue";
import NationalPatentCountryList from "@/@modules/Lists/NationalPatent/NationalPatentSubTables/NationalPatentCountryList";
import NationalPatentCountryForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentCountryForm";
import NationalPatentCountryShowHistoryListForm from "@/@modules/Forms/NationalPatent/NationalPatentSubForms/NationalPatentCountryShowHistoryListForm";
import EmploymentContractList from "@/@modules/Lists/EmploymentConract/EmploymentContractList";
import EmploymentContractForm from "@/@modules/Forms/EmploymentContract/EmploymentContractForm";
import EmploymentContractTypeList from "@/@modules/Lists/EmploymentConractType/EmploymentContractTypeList";
import EmploymentContractTypeForm from "@/@modules/Forms/EmploymentContractType/EmploymentContractTypeForm";
import EmployeeContractList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeContractList";
import JobReminderCriterionList from "@/@modules/Lists/Job/JobSubList/JobReminderCriterionList";
import JobReminderCriterionForm from "@/@modules/Forms/Job/JobSubForm/JobReminderCriterionForm";
import JobStatusRelationForm from "@/@modules/Forms/Job/JobSubForm/JobStatusRelationForm";
import JobStatusRelationList from "@/@modules/Lists/Job/JobSubList/JobStatusRelationList";
import TimeTypeForm from "@/@modules/Forms/Job/JobSubForm/TimeTypeForm";
import TimeTypeList from "@/@modules/Lists/Job/JobSubList/TimeTypeList";
import JobStatusRelationDetailList from "@/@modules/Lists/Job/JobSubList/JobStatusRelationDetailList";
import JobStatusRelationDetailForm from "@/@modules/Forms/Job/JobSubForm/JobStatusRelationDetailForm";
import AnnualLeaveRightForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/AnnualLeaveRightForm";
import AnnualLeaveRightList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/AnnualLeaveRightList";
import AnnualLeaveStatusForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/AnnualLeaveStatusForm";
import AnnualLeaveStatusList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/AnnualLeaveStatusList";
import HourlyAnnualLeaveRequestForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/HourlyAnnualLeaveRequestForm";
import HourlyAnnualLeaveEmployeeList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/HourlyAnnualLeaveEmployeeList";
import AnnualLeaveApproveForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/AnnualLeaveApproveForm";
import AnnualLeaveEmployeeList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/AnnualLeaveEmployeeList";
import HourlyAnnualLeaveRequestApproveForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/HourlyAnnualLeaveRequestApproveForm";
import HourlyAnnualLeaveRequestApproveList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/HourlyAnnualLeaveRequestApproveList";
import EmployeeHourlyAnnualLeaveRequestForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeHourlyAnnualLeaveRequestForm";
import EmployeeHourlyAnnualLeaveList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeHourlyAnnualLeaveList";
import EmployeeAnnualLeaveRequestForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeAnnualLeaveRequestForm";
import EmployeeAnnualLeaveList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeAnnualLeaveList";
import EmployeeAddressForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeAddressForm";
import EmployeeAddressList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeAddressList";
import EmployeeEducationInformationList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeEducationInformationList";
import EmployeeEducationInformationForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeEducationInformationForm";
import EmployeePowerOfAttorneyList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeePowerOfAttorneyList";
import EmployeePowerOfAttorneyForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeePowerOfAttorneyForm";
import EmployeeContactList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeContactList";
import EmployeeContactForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeContactForm";
import EmployeeLanguageList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/EmployeeLanguageList";
import EmployeeLanguageForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/EmployeeLanguageForm";
import HourlyAnnualLeaveReportList from "@/@modules/Lists/HumanResources/HumanResourcesReports/HourlyAnnualLeaveReportList";
import ClientAccountingInvoiceAlertDayReport from "@/@modules/Lists/Client/ClientReports/ClientAccountingInvoiceAlertDayReport";
import ClientAccountingSpecialInvoiceReport from "@/@modules/Lists/Client/ClientReports/ClientAccountingSpecialInvoiceReport";
import ClientAccountingDifferentInvoiceReport from "@/@modules/Lists/Client/ClientReports/ClientAccountingDifferentInvoiceReport";
import Dashboard from "@/views/pages/Dashboard/Dashboard.vue";
import MailToReadList from "@/@modules/Lists/MailToRead/MailToReadList";
import MailToReadForm from "@/@modules/Forms/MailToRead/MailToReadForm";
import NoteToBeOnInvoiceList from "@/@modules/Lists/NoteToBeOnInvoice/NoteToBeOnInvoiceList";
import NoteToBeOnInvoiceForm from "@/@modules/Forms/NoteToBeOnInvoice/NoteToBeOnInvoiceForm";
import InvoiceApprovalList from "@/@modules/Lists/Finance/Accounting/InvoiceApprovalList";
import InvoiceList from "@/@modules/Lists/Finance/Invoice/InvoiceList";
import InvoiceForm from "@/@modules/Forms/Finance/Invoice/InvoiceForm";
import InvoicePdfForm from "@/@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoicePdfForm.vue";
import InvoiceAddItemForm from "@/@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoiceAddItemForm.vue";
import InvoiceAddDailyworkForm from "@/@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoiceAddDailyworkForm.vue";
import InvoiceUpdateItemForm from "@/@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoiceUpdateItemForm.vue";
import InvoiceDiscountForm from "@/@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoiceDiscountForm.vue";
import TranslatorPaymentsList from "@/@modules/Lists/Finance/Accounting/TranslatorPaymentsList";
import TranslatorPaymentsForm from "@/@modules/Forms/Finance/Accounting/TranslatorPaymentsForm";
import ExchangeRateCalculatorForm from "@/@modules/Forms/ExchangeRateCalculator/ExchangeRateCalculatorForm";
import TotalImageUploadForm from "@/@modules/Forms/TotalImageUpload/TotalImageUploadForm";
import InvoiceHeaderList from "@/@modules/Lists/Finance/Definitions/InvoiceHeaderList";
import InvoiceHeaderForm from "@/@modules/Forms/Finance/Definitions/InvoiceHeaderForm";
import AdminEmployeeHourlyAnnualLeaveList from "@/@modules/Lists/Admin/AdminSubList/AdminEmployeeHourlyAnnualLeaveList";
import AdminEmployeeAnnualLeaveList from "@/@modules/Lists/Admin/AdminSubList/AdminEmployeeAnnualLeaveList";
import TranslatorPaymentTranslationsList from "@/@modules/Lists/Finance/Accounting/TranslatorPaymentSubList/TranslatorPaymentTranslationsList";
import AdminAnnualLeaveApproveForm from "@/@modules/Forms/Admin/AdminSubForm/AdminAnnualLeaveApproveForm";
import EmployeeHourlyAdminAnnualLeaveRequestForm from "@/@modules/Forms/Admin/AdminSubForm/EmployeeHourlyAdminAnnualLeaveRequestForm";
import ServiceTypeInvoiceHeaderList from "@/@modules/Lists/Service/ServiceTypeInvoiceHeaderList";
import ServiceTypeInvoiceHeaderForm from "@/@modules/Forms/Service/ServiceTypeInvoiceHeaderForm";
import LedesList from "@/@modules/Lists/Finance/Definitions/LedesList";
import LedesForm from "@/@modules/Forms/Finance/Definitions/LedesForm";
import LedesDetailList from "@/@modules/Lists/Finance/Definitions/LedesDetailList";
import LedesDetailForm from "@/@modules/Forms/Finance/Definitions/LedesDetailForm";
import LedesCodeTypeList from "@/@modules/Lists/Finance/Definitions/LedesCodeTypeList";
import LedesCodeTypeForm from "@/@modules/Forms/Finance/Definitions/LedesCodeTypeForm";
import LedesTypeList from "@/@modules/Lists/Finance/Definitions/LedesTypeList";
import LedesTypeForm from "@/@modules/Forms/Finance/Definitions/LedesTypeForm";
import ReminderList from "@/@modules/Lists/Reminder/ReminderList";
import ReminderReportList from "@/@modules/Lists/Reminder/ReminderReportList";
import AnnualLeaveReportList from "@/@modules/Lists/HumanResources/HumanResourcesReports/AnnualLeaveReportList";
import OvertimeWorkTypeForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/OvertimeWork/OvertimeWorkTypeForm";
import OvertimeWorkTypeList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/OvertimeWork/OvertimeWorkTypeList";
import OvertimeWorkReasonForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/OvertimeWork/OvertimeWorkReasonForm";
import OvertimeWorkReasonList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/OvertimeWork/OvertimeWorkReasonList";
import OvertimeWorkList from "@/@modules/Lists/HumanResources/HumanResourcesSubList/OvertimeWork/OvertimeWorkList";
import OvertimeWorkListForEmployee from "@/@modules/Lists/HumanResources/HumanResourcesSubList/OvertimeWork/OvertimeWorkListForEmployee";
import OvertimeWorkListForManager from "@/@modules/Lists/Admin/AdminSubList/OvertimeWorkListForManager";
import OvertimeWorkForm from "@/@modules/Forms/HumanResources/HumanResourcesSubForm/OvertimeWork/OvertimeWorkForm";
import OvertimeWorkApproveForm from "@/@modules/Forms/Admin/AdminSubForm/OvertimeWorkApproveForm";
import InvoiceStatusForm from "@/@modules/Forms/Finance/Definitions/InvoiceStatusForm";
import InvoiceStatusList from "@/@modules/Lists/Finance/Definitions/InvoiceStatusList";
import CreditCardForm from "@/@modules/Forms/Finance/Definitions/CreditCardForm";
import CreditCardList from "@/@modules/Lists/Finance/Definitions/CreditCardList";
import OfficialFeeForm from "@/@modules/Forms/Finance/OfficialFeeForm";
import OfficialFeeList from "@/@modules/Lists/Finance/OfficialFeeList";
import JobForm from "@/@modules/Forms/Job/JobForm";
import JobList from "@/@modules/Lists/Job/JobList";
import JobDetailForm from "@/@modules/Forms/Job/JobSubForm/JobDetailForm";
import JobNextForm from "@/@modules/Forms/Job/JobSubForm/JobNextForm";
import JobReportList from "@/@modules/Lists/Job/JobSubList/JobReportList";
import JobHistoryList from "@/@modules/Lists/Job/JobSubList/JobHistoryList";
import JobHistoryForm from "@/@modules/Forms/Job/JobSubForm/JobHistoryForm";
import DailyworkList from "@/@modules/Lists/Dailywork/DailyworkList";
import DailyworkForm from "@/@modules/Forms/Dailywork/DailyworkForm";
import MultiDailyworkSaveForm from "@/@modules/Forms/Dailywork/DailyworkSubForm/MultiDailyworkSaveForm";
import DailyworkOfficialFeeForm from "@/@modules/Forms/Dailywork/DailyworkSubForm/DailyworkOfficialFeeForm";
import DailyworkReport from "@/@modules/Lists/Dailywork/Reports/DailyworkReport";
import BillingStatusForm from "@/@modules/Forms/Finance/Definitions/BillingStatusForm";
import BillingStatusList from "@/@modules/Lists/Finance/Definitions/BillingStatusList";
import ClientAccountingCategoryForm from "@/@modules/Forms/Client/Definitions/ClientAccountingCategoryForm";
import ClientAccountingCategoryList from "@/@modules/Lists/Client/Definitions/ClientAccountingCategoryList";
import InvoiceDiscountFormatForm from "@/@modules/Forms/Finance/Definitions/InvoiceDiscountFormatForm";
import InvoiceDiscountFormatList from "@/@modules/Lists/Finance/Definitions/InvoiceDiscountFormatList";
import PatentSearchDatabaseList from "@/@modules/Lists/PatentSearchDatabase/PatentSearchDatabaseList";
import PatentSearchDatabaseForm from "@/@modules/Forms/PatentSearchDatabase/PatentSearchDatabaseForm";
import BankList from "@/@modules/Lists/Finance/Definitions/BankList.vue";
import BankForm from "@/@modules/Forms/Finance/Definitions/BankForm.vue";
import PaymentList from "@/@modules/Lists/Finance/Accounting/PaymentList.vue";
import PaymentForm from "@/@modules/Forms/Finance/Accounting/PaymentForm.vue";
import PaymentDetailList from "@/@modules/Lists/Finance/Accounting/PaymentSubList/PaymentDetailList.vue";
import NationalTrademarkCountryNiceClassForm from "@/@modules/Forms/NationalTrademark/NationalTrademarkSubForms/NationalTrademarkCountryNiceClassForm.vue";
import NationalTrademarkCountryNiceClassList from "@/@modules/Lists/NationalTrademark/NationalTrademarkSubTables/NationalTrademarkCountryNiceClassList.vue";
import FindRecordForm from "@/@modules/Forms/FindRecord/FindRecordForm.vue";
import TrademarkObjectionToOpposingViewStatusForm from "@/@modules/Forms/TrademarkObjectionToUsStatuses/TrademarkObjectionToOpposingViewStatusForm";
import TrademarkObjectionToOpposingViewStatusList from "@/@modules/Lists/TrademarkObjectionToUsStatuses/TrademarkObjectionToOpposingViewStatusList";
import TrademarkObjectionToUsCaseDecisionForm from "@/@modules/Forms/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsCaseDecisionForm";
import TrademarkObjectionToUsCaseDecisionList from "@/@modules/Lists/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsCaseDecisionList";
import TrademarkObjectionToUsDecisionForm from "@/@modules/Forms/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsDecisionForm";
import TrademarkObjectionToUsDecisionList from "@/@modules/Lists/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsDecisionList";
import TrademarkObjectionToUsStatusForm from "@/@modules/Forms/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsStatusForm";
import TrademarkObjectionToUsStatusList from "@/@modules/Lists/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsStatusList";
import TrademarkObjectionToUsYidkStatusForm from "@/@modules/Forms/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsYidkStatusForm";
import TrademarkObjectionToUsYidkStatusList from "@/@modules/Lists/TrademarkObjectionToUsStatuses/TrademarkObjectionToUsYidkStatusList";
import TrademarkObjectionToUsForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkObjectionToUsForm";
import TrademarkObjectionToUsList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkObjectionToUsList";
import TrademarkProvisionalList from "@/@modules/Lists/Trademark/TrademarkSubTables/TrademarkProvisionalList";
import TrademarkProvisionalForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkProvisionalForm.vue";
import TrademarkProvisionalStatusList from "@/@modules/Lists/TrademarkProvisionalStatuses/TrademarkProvisionalStatusList";
import TrademarkProvisionalStatusForm from "@/@modules/Forms/TrademarkProvisionalStatuses/TrademarkProvisionalStatusForm";
import TrademarkProvisionalCaseInstructionList from "@/@modules/Lists/TrademarkProvisionalStatuses/TrademarkProvisionalCaseInstructionList";
import TrademarkProvisionalCaseInstructionForm from "@/@modules/Forms/TrademarkProvisionalStatuses/TrademarkProvisionalCaseInstructionForm";
import TrademarkBulletinCheckList from "@/@modules/Lists/BulletinReport/TrademarkBulletinCheckList";
import TrademarkBulletinCheckForm from "@/@modules/Forms/BulletinReport/TrademarkBulletinCheckForm";
import MailHistoryList from "@/@modules/Lists/MailHistory/MailHistoryList";
import MailHistoryForm from "@/@modules/Forms/MailHistory/MailHistoryForm";
import AgentTransactionsReportList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportList";
import AgentTransactionsReportTrademarkList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportTrademarkList";
import AgentTransactionsReportTrademarkProvisionalList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportTrademarkProvisionalList";
import AgentTransactionsReportTrademarkObjectionToUsList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportTrademarkObjectionToUsList";
import AgentTransactionsReportTrademarkOppositionList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportTrademarkOppositionList";
import AgentTransactionsReportNationalTrademarkList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportNationalTrademarkList";
import AgentTransactionsReportPatentList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportPatentList";
import AgentTransactionsReportDesignList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportDesignList";
import AgentTransactionsReportDesignOppositionList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportDesignOppositionList";
import AgentTransactionsReportDomainList from "@/@modules/Lists/Client/ClientReports/AgentTransactionsReportDomainList";
import EInvoiceList from "@/@modules/Lists/Finance/Accounting/EInvoiceList";
import EInvoiceForm from "@/@modules/Forms/Finance/Accounting/EInvoiceForm";
import EInvoiceAwaitingApprovalList from "@/@modules/Lists/Finance/Accounting/EInvoiceSubList/EInvoiceAwaitingApprovalList";
import EtebsList from "@/@modules/Lists/Tpmk/Etebs/EtebsList";
import EtebsForm from "@/@modules/Forms/Tpmk/Etebs/EtebsForm";
import OpenJobListForm from "@/@modules/Forms/Tpmk/Etebs/EtebsSubForm/OpenJobListForm";
import PatentWipoXmlUploadForm from "./@modules/Forms/Patent/PatentSubForms/PatentWipoXmlUploadForm.vue";
import WipoXmlForm from "./@modules/Forms/WipoXml/WipoXmlForm.vue";
import UploadFromEtebsForm from "@/@modules/Forms/Tpmk/Etebs/EtebsSubForm/UploadFromEtebsForm";
import AccountingBooksList from "@/@modules/Lists/Finance/Accounting/AccountingBooksList.vue";
import AccountingBooksForm from "@/@modules/Forms/Finance/Accounting/AccountingBooksForm.vue";
import AccountingBooksAccountsList from "@/@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingBooksAccountsList.vue";
import AccountingBooksAccountsForm from "@/@modules/Forms/Finance/Accounting/AccountingBooksSubForm/AccountingBooksAccountsForm.vue";
import AccountingBooksReceiptList from "@/@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingBooksReceiptList.vue";
import AccountingBooksReceiptForm from "@/@modules/Forms/Finance/Accounting/AccountingBooksSubForm/AccountingBooksReceiptForm.vue";
import AccountingBooksReceiptStandartList from "@/@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingBooksReceiptStandartList.vue";
import AccountingBooksReceiptStandartForm from "@/@modules/Forms/Finance/Accounting/AccountingBooksSubForm/AccountingBooksReceiptStandartForm.vue";
import StatementList from "@/@modules/Lists/Finance/Accounting/StatementList.vue";
import InvoiceAgingReportList from "@/@modules/Lists/Finance/Accounting/InvoiceAgingReportList.vue";
import TrademarkWipoXmlUploadForm from "@/@modules/Forms/Trademark/TrademarkSubForms/TrademarkWipoXmlUploadForm.vue";
import TrademarkWipoXmlForm from "@/@modules/Forms/WipoXml/TrademarkWipoXmlForm";
import NationalTrademarkTransactionReportList from "@/@modules/Lists/NationalTrademark/Reports/NationalTrademarkTransactionReportList";
import NationalTrademarkTransactionReportDetail from "@/@modules/Lists/NationalTrademark/Reports/NationalTrademarkTransactionReportDetail";
import TrademarkOppositionStatusList from "./@modules/Lists/TrademarkOppositionStatus/TrademarkOppositionStatusList.vue";
import TrademarkOppositionStatusForm from "./@modules/Forms/TrademarkOppositionStatus/TrademarkOppositionStatusForm.vue";
import InvoiceEditNoteForm from "./@modules/Forms/Finance/Accounting/InvoiceEditNoteForm.vue";
import NationalTrademarkSearchStatusForm from "./@modules/Forms/NationalTrademarkSearchStatus/NationalTrademarkSearchStatusForm.vue";
import NationalTrademarkSearchStatusList from "./@modules/Lists/NationalTrademarkSearchStatus/NationalTrademarkSearchStatusList.vue";
import ClientFolderList from "./@modules/Lists/Client/ClientSubTables/ClientFolderList.vue";
import TrademarkDetailForm from "./@modules/Forms/Trademark/TrademarkSubForms/TrademarkDetailForm.vue";
import ClientInvoiceReportList from "./@modules/Lists/Finance/ClientInvoiceReport/ClientInvoiceReportList.vue";
import ClientInvoiceReportListCallForm from "./@modules/Forms/Client/ClientSubForms/ClientInvoiceReportListCallForm.vue";
import JobUpdateNoteForm from "./@modules/Forms/Job/JobSubForm/JobUpdateNoteForm.vue";
import BusinessPartnerTypeList from "./@modules/Lists/Finance/Accounting/BusinessPartnerType/BusinessPartnerTypeList.vue";
import BusinessPartnerTypeForm from "./@modules/Forms/Finance/Accounting/BusinessPartnerType/BusinessPartnerTypeForm.vue";
import BusinessPartnerResponsibleList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/BusinessPartnerSubList/BusinessPartnerResponsibleList.vue";
import BusinessPartnerResponsibleForm from "./@modules/Forms/Finance/Accounting/AccountingBooksSubForm/BusinessPartnerSubForm/BusinessPartnerResponsibleForm.vue";
import BusinessPartnerAddressList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/BusinessPartnerSubList/BusinessPartnerAddressList.vue";
import BusinessPartnerAddressForm from "./@modules/Forms/Finance/Accounting/AccountingBooksSubForm/BusinessPartnerSubForm/BusinessPartnerAddressForm.vue";
import BusinessPartnerContactInformationList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/BusinessPartnerSubList/BusinessPartnerContactInformationList.vue";
import BusinessPartnerContactInformationForm from "./@modules/Forms/Finance/Accounting/AccountingBooksSubForm/BusinessPartnerSubForm/BusinessPartnerContactInformationForm.vue";
import BusinessPartnerList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/BusinessPartnerList.vue";
import BusinessPartnerForm from "./@modules/Forms/Finance/Accounting/AccountingBooksSubForm/BusinessPartnerForm.vue";
import AccountingTransactionTypeForm from "./@modules/Forms/Finance/Accounting/AccountingBooksSubForm/AccountingTransactionTypeForm.vue";
import AccountingTransactionTypeList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingTransactionTypeList.vue";
import AccountingTransactionDoesntBalancedEntitiesReportList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingTransactionDoesntBalancedEntitiesReportList.vue";
import InvoiceAddCautionForm from "./@modules/Forms/Finance/Invoice/InvoiceSubForm/InvoiceAddCautionForm.vue";
import SearchTrademarkForCopyForm from "./@modules/Forms/Trademark/TrademarkSubForms/SearchTrademarkForCopyForm.vue";
import XmlOwnerClientSearchListCallForm from "./@modules/Forms/Client/ClientSubForms/XmlOwnerClientSearchListCallForm.vue";
import XmlOwnerClientSearchList from "./@modules/Lists/Client/ClientSubTables/XmlOwnerClientSearchList.vue";
import AgentTransactionsReportTrademarkSearchList from "./@modules/Lists/Client/ClientReports/AgentTransactionsReportTrademarkSearchList";
import AccountingTransactionLedgerReportList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingTransactionLedgerReportList";
import AccountingTransactionZeroSequenceNoReportList from "./@modules/Lists/Finance/Accounting/AccountingBooksSubList/AccountingTransactionZeroSequenceNoReportList.vue";
import AnnuityDetailStatusContentModalForm from "./@modules/Forms/Annuity/AnnuityDetail/AnnuityDetailStatusContentModalForm.vue";

//components
Vue.component("BCardHeader", BCardHeader);
Vue.component("BCardText", BCardText);
Vue.component("VueGoodTable", VueGoodTable);
Vue.component("BPagination", BPagination);
Vue.component("BFormGroup", BFormGroup);
Vue.component("BForm", BForm);
Vue.component("BBadge", BBadge);
Vue.component("BFormTextarea", BFormTextarea);
Vue.component("BFormInput", BFormInput);
Vue.component("BFormSelect", BFormSelect);
Vue.component("BFormSelectOption", BFormSelectOption);
Vue.component("BMedia", BMedia);
Vue.component("BAvatar", BAvatar);
Vue.component("BPopover", BPopover);
Vue.component("BTable", BTable);
Vue.component("BMediaAside", BMediaAside);
Vue.component("BMediaBody", BMediaBody);
Vue.component("BRow", BRow);
Vue.component("BCol", BCol);
Vue.component("BTabs", BTabs);
Vue.component("BTab", BTab);
Vue.component("BDropdownForm", BDropdownForm);
Vue.component("BFormFile", BFormFile);
Vue.component("BButton", BButton);
Vue.component("BInputGroup", BInputGroup);
Vue.component("BInputGroupPrepend", BInputGroupPrepend);
Vue.component("BInputGroupAppend", BInputGroupAppend);
Vue.component("BFormCheckbox", BFormCheckbox);
Vue.component("BFormRadioGroup", BFormRadioGroup);
Vue.component("BFormRadio", BFormRadio);
Vue.component("AppCollapseItem", AppCollapseItem);
Vue.component("AppCollapse", AppCollapse);
Vue.component("BCardCode", BCardCode);
Vue.component("BCard", BCard);
Vue.component("BLink", BLink);
Vue.component("BImg", BImg);
Vue.component("VuexyLogo", VuexyLogo);
Vue.component("BCardTitle", BCardTitle);
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("required", required);
Vue.component("vSelect", vSelect);
Vue.component("BFormCheckboxGroup", BFormCheckboxGroup);
Vue.component("BFormInvalidFeedback", BFormInvalidFeedback);
Vue.component("BFormValidFeedback", BFormValidFeedback);
Vue.component("BDropdownItem", BDropdownItem);
Vue.component("BDropdown", BDropdown);
Vue.component("Cleave", Cleave);
Vue.component("BCardBody", BCardBody);
Vue.component("flatPickr", flatPickr);
Vue.component("FlipCountdown", FlipCountdown);
Vue.component("BFormDatepicker", BFormDatepicker);
Vue.component("BTableSimple", BTableSimple);
Vue.component("BThead", BThead);
Vue.component("BTr", BTr);
Vue.component("BTh", BTh);
Vue.component("BTd", BTd);
Vue.component("BTbody", BTbody);
Vue.component("BTfoot", BTfoot);
Vue.component("BListGroup", BListGroup);
Vue.component("BListGroupItem", BListGroupItem);
Vue.component("BFormTimepicker", BFormTimepicker);
Vue.component("VueApexCharts", VueApexCharts);
Vue.component("draggable", draggable);
Vue.component("BAlert", BAlert);
Vue.component("BJumbotron", BJumbotron);
// Vue.component('Loading', Loading);
Vue.use(Loading);
Vue.component("FormWizard", FormWizard);
Vue.component("TabContent", TabContent);

//Pages for component
Vue.component("CustomDatePicker", CustomDatePicker);
Vue.component("departmentForm", departmentForm);
Vue.component("DepartmentList", DepartmentList);
Vue.component("MailAccountForm", MailAccountForm);
Vue.component("MailAccountList", MailAccountList);
Vue.component("MailTemplateForm", MailTemplateForm);
Vue.component("MailTemplateList", MailTemplateList);
Vue.component("PermissionGroupForm", PermissionGroupForm);
Vue.component("PermissionGroupList", PermissionGroupList);
Vue.component("PermissionTemplateList", PermissionTemplateList);
Vue.component("PermissionTemplateForm", PermissionTemplateForm);
Vue.component("PermissionForm", PermissionForm);
Vue.component("PermissionList", PermissionList);
Vue.component("EmployeeClassificationForm", EmployeeClassificationForm);
Vue.component("EmployeeClassificationList", EmployeeClassificationList);
Vue.component("CountryList", CountryList);
Vue.component("CountryForm", CountryForm);
Vue.component("EmployeeList", EmployeeList);
Vue.component("MyAccountGeneral", MyAccountGeneral);
Vue.component("MyAccountPassword", MyAccountPassword);
Vue.component("MyAccountForm", MyAccountForm);
Vue.component("EmployeePriceForm", EmployeePriceForm);
Vue.component("EmployeeForm", EmployeeForm);
Vue.component("EmployeePhotoCropperForm", EmployeePhotoCropperForm);
Vue.component("EmployeeNoteForm", EmployeeNoteForm);
Vue.component("EmployeePermissionTableForm", EmployeePermissionTableForm);
Vue.component("ChangePassword", ChangePassword);
Vue.component("WordTemplatesForm", WordTemplatesForm);
Vue.component("WordTemplatesList", WordTemplatesList);
Vue.component("ClientForm", ClientForm);
Vue.component("ClientList", ClientList);
Vue.component("ClientAddressList", ClientAddressList);
Vue.component("ClientContactList", ClientContactList);
Vue.component("ClientResponsibleList", ClientResponsibleList);
Vue.component("ClientAccountingList", ClientAccountingList);
Vue.component("ClientAddressForm", ClientAddressForm);
Vue.component("ClientContactForm", ClientContactForm);
Vue.component("ClientResponsibleForm", ClientResponsibleForm);
Vue.component("DocumentsForm", DocumentsForm);
Vue.component("DocumentsList", DocumentsList);
Vue.component("ClientAccountingForm", ClientAccountingForm);
Vue.component("ClientHistoryList", ClientHistoryList);
Vue.component("ClientMergeCompanyForm", ClientMergeCompanyForm);
Vue.component("ClientLiableForm", ClientLiableForm);
Vue.component("ClientLiableList", ClientLiableList);
Vue.component("ContactTypeList", ContactTypeList);
Vue.component("ContactTypeForm", ContactTypeForm);
Vue.component("MailContactTypeList", MailContactTypeList);
Vue.component("MailContactTypeForm", MailContactTypeForm);
Vue.component("ClientNoteForm", ClientNoteForm);
Vue.component("ClientNoteList", ClientNoteList);
Vue.component("InvoiceSendTypeList", InvoiceSendTypeList);
Vue.component("InvoiceSendTypeForm", InvoiceSendTypeForm);
Vue.component("ResponsibleFieldList", ResponsibleFieldList);
Vue.component("ResponsibleFieldForm", ResponsibleFieldForm);
Vue.component("ClientConflictList", ClientConflictList);
Vue.component("ClientConflictForm", ClientConflictForm);
Vue.component("PriceListsList", PriceListsList);
Vue.component("PriceListsForm", PriceListsForm);
Vue.component("DocumentTypeList", DocumentTypeList);
Vue.component("DocumentTypeForm", DocumentTypeForm);
Vue.component("DocumentTypeDetailForm", DocumentTypeDetailForm);
Vue.component("DocumentTypeDetailList", DocumentTypeDetailList);
Vue.component("PositionForm", PositionForm);
Vue.component("PositionList", PositionList);
Vue.component("TitleForm", TitleForm);
Vue.component("TitleList", TitleList);
Vue.component("LiableFieldList", LiableFieldList);
Vue.component("LiableFieldForm", LiableFieldForm);
Vue.component("ClientResponsibleReportList", ClientResponsibleReportList);
Vue.component("EmployeePriceList", EmployeePriceList);
Vue.component("EmployeeNoteList", EmployeeNoteList);
Vue.component("SectorForm", SectorForm);
Vue.component("SectorList", SectorList);
Vue.component("DomainList", DomainList);
Vue.component("DomainForm", DomainForm);
Vue.component("DomainResponsibleForm", DomainResponsibleForm);
Vue.component("DomainResponsibleList", DomainResponsibleList);
Vue.component("DomainOwnerForm", DomainOwnerForm);
Vue.component("DomainOwnerList", DomainOwnerList);
Vue.component("DomainOppositionList", DomainOppositionList);
Vue.component("DomainOppositionForm", DomainOppositionForm);
Vue.component("DomainOppositionOwnerForm", DomainOppositionOwnerForm);
Vue.component("DomainOppositionOwnerList", DomainOppositionOwnerList);
Vue.component("MailSendForm", MailSendForm);
Vue.component( "DomainOppositionResponsibleForm", DomainOppositionResponsibleForm);
Vue.component( "DomainOppositionResponsibleList", DomainOppositionResponsibleList);
Vue.component("DomainOppositionBasisForOppositionList",DomainOppositionBasisForOppositionList);
Vue.component("DomainOppositionBasisForOppositionForm",DomainOppositionBasisForOppositionForm);
Vue.component("DesignList", DesignList);
Vue.component("DesignForm", DesignForm);
Vue.component("DesignOwnerList", DesignOwnerList);
Vue.component("DesignOwnerForm", DesignOwnerForm);
Vue.component("DesignResponsibleList", DesignResponsibleList);
Vue.component("DesignResponsibleForm", DesignResponsibleForm);
Vue.component("DesignDesignerForm", DesignDesignerForm);
Vue.component("DesignDesignerList", DesignDesignerList);
Vue.component("DesignRenewalList", DesignRenewalList);
Vue.component("DesignRenewalForm", DesignRenewalForm);
Vue.component("DesignObjectionToUsForm", DesignObjectionToUsForm);
Vue.component("DesignObjectionToUsList", DesignObjectionToUsList);
Vue.component("DesignPriorityForm", DesignPriorityForm);
Vue.component("DesignPriorityList", DesignPriorityList);
Vue.component("DesignOppositionList", DesignOppositionList);
Vue.component("DesignOppositionForm", DesignOppositionForm);
Vue.component("DesignOppositionOwnerForm", DesignOppositionOwnerForm);
Vue.component("DesignOppositionOwnerList", DesignOppositionOwnerList);
Vue.component("DesignOppositionResponsibleForm",DesignOppositionResponsibleForm);
Vue.component("DesignOppositionResponsibleList",DesignOppositionResponsibleList);
Vue.component("DesignOppositionBasisForOppositionForm",DesignOppositionBasisForOppositionForm);
Vue.component("DesignOppositionBasisForOppositionList",DesignOppositionBasisForOppositionList);
Vue.component("PatentList", PatentList);
Vue.component("PatentForm", PatentForm);
Vue.component("PatentPriorityList", PatentPriorityList);
Vue.component("PatentPriorityForm", PatentPriorityForm);
Vue.component("PatentOwnerList", PatentOwnerList);
Vue.component("PatentOwnerForm", PatentOwnerForm);
Vue.component("PatentResponsibleList", PatentResponsibleList);
Vue.component("PatentResponsibleForm", PatentResponsibleForm);
Vue.component("PatentInventorList", PatentInventorList);
Vue.component("PatentInventorForm", PatentInventorForm);
Vue.component("TrademarkList", TrademarkList);
Vue.component("TrademarkForm", TrademarkForm);
Vue.component("TrademarkResponsibleList", TrademarkResponsibleList);
Vue.component("TrademarkResponsibleForm", TrademarkResponsibleForm);
Vue.component("TrademarkOwnerList", TrademarkOwnerList);
Vue.component("TrademarkOwnerForm", TrademarkOwnerForm);
Vue.component("TrademarkPriorityList", TrademarkPriorityList);
Vue.component("TrademarkPriorityForm", TrademarkPriorityForm);
Vue.component("TrademarkViennaClassesList", TrademarkViennaClassesList);
Vue.component("TrademarkViennaClassesForm", TrademarkViennaClassesForm);
Vue.component("TrademarkNiceClassList", TrademarkNiceClassList);
Vue.component("TrademarkNiceClassForm", TrademarkNiceClassForm);
Vue.component("Login", Login);
Vue.component("LoginModalForm", LoginModalForm);
Vue.component("BaseTimer", BaseTimer);
Vue.component("AgentNoteForm", AgentNoteForm);
Vue.component("OwnerNoteForm", OwnerNoteForm);
Vue.component("DesignProductList", DesignProductList);
Vue.component("DesignProductForm", DesignProductForm);
Vue.component("HolidayForm", HolidayForm);
Vue.component("HolidayList", HolidayList);
Vue.component("DesignDeadlineReportList", DesignDeadlineReportList);
Vue.component("DesignBulletinCheckReportList", DesignBulletinCheckReportList);
Vue.component("DesignBulletinCheckReportForm", DesignBulletinCheckReportForm);
Vue.component("PatentBulletinCheckList", PatentBulletinCheckList);
Vue.component("PatentBulletinCheckForm", PatentBulletinCheckForm);
Vue.component("TranslatorForm", TranslatorForm);
Vue.component("TranslatorList", TranslatorList);
Vue.component( "TranslatorContactInformationForm", TranslatorContactInformationForm);
Vue.component( "TranslatorContactInformationList", TranslatorContactInformationList);
Vue.component("TranslatorPriceForm", TranslatorPriceForm);
Vue.component("TranslatorPriceList", TranslatorPriceList);
Vue.component("PatentStatusForm", PatentStatusForm);
Vue.component("PatentStatusList", PatentStatusList);
Vue.component("PatentHistoryForm", PatentHistoryForm);
Vue.component("PatentHistoryList", PatentHistoryList);
Vue.component("AnnualLeaveTypeList", AnnualLeaveTypeList);
Vue.component("AnnualLeaveTypeForm", AnnualLeaveTypeForm);
Vue.component("LanguageList", LanguageList);
Vue.component("LanguageForm", LanguageForm);
Vue.component("PatentUpdateFieldsForm", PatentUpdateFieldsForm);
Vue.component("PatentUpdateFieldsList", PatentUpdateFieldsList);
Vue.component("PatentXmlUploadForm", PatentXmlUploadForm);
Vue.component("PatentDivisionForm", PatentDivisionForm);
Vue.component("PatentDivisionList", PatentDivisionList);
Vue.component("PatentTranslateList", PatentTranslateList);
Vue.component("PatentTranslateForm", PatentTranslateForm);
Vue.component("TranslateReportList", TranslateReportList);
Vue.component("PatentTradosUploadForm", PatentTradosUploadForm);
Vue.component("PatentTranslateList", PatentTranslateList);
Vue.component("PatentTranslateForm", PatentTranslateForm);
Vue.component("BulletinUploadForm", BulletinUploadForm);
Vue.component("LexologyList", LexologyList);
Vue.component("LexologyForm", LexologyForm);
Vue.component( "PatentBulletinAnalyseReportList", PatentBulletinAnalyseReportList);
Vue.component( "DesignBulletinAnalyseReportList", DesignBulletinAnalyseReportList);
Vue.component( "TrademarkBulletinAnalyseReportList", TrademarkBulletinAnalyseReportList);
Vue.component("ServiceTypeList", ServiceTypeList);
Vue.component("ServiceTypeForm", ServiceTypeForm);
Vue.component("ServiceList", ServiceList);
Vue.component("ServiceForm", ServiceForm);
Vue.component("PDFViewer", PDFViewer);
Vue.component( "DesignBulletinAnalyseReportForm", DesignBulletinAnalyseReportForm);
Vue.component("PriceListAgentsList", PriceListAgentsList);
Vue.component("PriceListDetailList", PriceListDetailList);
Vue.component("EpoXmlForm", EpoXmlForm);
Vue.component("PatentBulletinAnalyseReportForm",PatentBulletinAnalyseReportForm);
Vue.component("TrademarkBulletinAnalyseReportForm",TrademarkBulletinAnalyseReportForm);
Vue.component("ServiceFeeList", ServiceFeeList);
Vue.component("AgentAnnuityPriceList", AgentAnnuityPriceList);
Vue.component("AgentAnnuityPriceForm", AgentAnnuityPriceForm);
Vue.component("AnnuityList", AnnuityList);
Vue.component("AnnuityForm", AnnuityForm);
Vue.component("AnnuityDetailList", AnnuityDetailList);
Vue.component("AnnuityDetailForm", AnnuityDetailForm);
Vue.component("AnnuityDetailListForInvoice", AnnuityDetailListForInvoice);
Vue.component("TpmkExcelSaveForm", TpmkExcelSaveForm);
Vue.component("AgentAnnuityPriceDetailForm", AgentAnnuityPriceDetailForm);
Vue.component("AgentAnnuityPriceDetailList", AgentAnnuityPriceDetailList);
Vue.component("AnnuityDetailStatusForm", AnnuityDetailStatusForm);
Vue.component("AnnuityDetailStatusList", AnnuityDetailStatusList);
Vue.component("DesignStatusList", DesignStatusList);
Vue.component("DesignStatusForm", DesignStatusForm);
Vue.component("NoteTypeList", NoteTypeList);
Vue.component("NoteTypeForm", NoteTypeForm);
Vue.component("PatentNoteTypeList", PatentNoteTypeList);
Vue.component("PatentNoteTypeForm", PatentNoteTypeForm);
Vue.component("PatentNoteForm", PatentNoteForm);
Vue.component("PatentNoteList", PatentNoteList);
Vue.component("PatentAnnuityStatusForm", PatentAnnuityStatusForm);
Vue.component("PatentAnnuityStatusList", PatentAnnuityStatusList);
Vue.component("PatentAnnuityWillPayForm", PatentAnnuityWillPayForm);
Vue.component("PatentAnnuityWillPayList", PatentAnnuityWillPayList);
Vue.component("PatentAnnuityForm", PatentAnnuityForm);
Vue.component("PatentAnnuityList", PatentAnnuityList);
Vue.component("PatentTransactionReport", PatentTransactionReport);
Vue.component("PatentTransactionReportDetail", PatentTransactionReportDetail);
Vue.component("TrademarkApplicationTypeForm", TrademarkApplicationTypeForm);
Vue.component("TrademarkApplicationTypeList", TrademarkApplicationTypeList);
Vue.component("TrademarkStatusForm", TrademarkStatusForm);
Vue.component("TrademarkStatusList", TrademarkStatusList);
Vue.component("TrademarkHistoryForm", TrademarkHistoryForm);
Vue.component("TrademarkHistoryList", TrademarkHistoryList);
Vue.component("NiceClassTypeForm", NiceClassTypeForm);
Vue.component("NiceClassTypeList", NiceClassTypeList);
Vue.component("ReminderForm", ReminderForm);
Vue.component("PatentBulletinReviewList", PatentBulletinReviewList);
Vue.component("TrademarkNoteForm", TrademarkNoteForm);
Vue.component("TrademarkSearchTypeForm", TrademarkSearchTypeForm);
Vue.component("TrademarkSearchTypeList", TrademarkSearchTypeList);
Vue.component("TrademarkSearchStatusForm", TrademarkSearchStatusForm);
Vue.component("TrademarkSearchStatusList", TrademarkSearchStatusList);
Vue.component("TrademarkNoteTypeForm", TrademarkNoteTypeForm);
Vue.component("TrademarkNoteTypeList", TrademarkNoteTypeList);
Vue.component("TrademarkNoteList", TrademarkNoteList);
Vue.component("PatentCertificateList", PatentCertificateList);
Vue.component("TrademarkTypeList", TrademarkTypeList);
Vue.component("TrademarkTypeForm", TrademarkTypeForm);
Vue.component("TrademarkSearchDatabaseList", TrademarkSearchDatabaseList);
Vue.component("TrademarkSearchDatabaseForm", TrademarkSearchDatabaseForm);
Vue.component("TrademarkSearchSuccessRateList", TrademarkSearchSuccessRateList);
Vue.component("TrademarkSearchSuccessRateForm", TrademarkSearchSuccessRateForm);
Vue.component("TrademarkSearchList", TrademarkSearchList);
Vue.component("TrademarkSearchForm", TrademarkSearchForm);
Vue.component("NationalTrademarkSearchList", NationalTrademarkSearchList);
Vue.component("NationalTrademarkSearchForm", NationalTrademarkSearchForm);
Vue.component("TrademarkSearchOwnerList", TrademarkSearchOwnerList);
Vue.component("TrademarkSearchOwnerForm", TrademarkSearchOwnerForm);
Vue.component( "NationalTrademarkSearchOwnerList", NationalTrademarkSearchOwnerList );
Vue.component( "NationalTrademarkSearchOwnerForm", NationalTrademarkSearchOwnerForm );
Vue.component("TrademarkSearchHistoryList", TrademarkSearchHistoryList);
Vue.component("TrademarkSearchHistoryForm", TrademarkSearchHistoryForm);
Vue.component( "NationalTrademarkSearchHistoryList", NationalTrademarkSearchHistoryList );
Vue.component( "NationalTrademarkSearchHistoryForm", NationalTrademarkSearchHistoryForm );
Vue.component("TrademarkSearchResponsibleList", TrademarkSearchResponsibleList);
Vue.component("TrademarkSearchResponsibleForm", TrademarkSearchResponsibleForm);
Vue.component( "NationalTrademarkSearchResponsibleList", NationalTrademarkSearchResponsibleList);
Vue.component("NationalTrademarkSearchResponsibleForm",NationalTrademarkSearchResponsibleForm);
Vue.component("TrademarkRenewalsList", TrademarkRenewalsList);
Vue.component("TrademarkRenewalsForm", TrademarkRenewalsForm);
Vue.component("PatentWorkingReminderList", PatentWorkingReminderList);
Vue.component("PatentAnnualFeeReminderList", PatentAnnualFeeReminderList);
Vue.component("TrademarkOppositionForm", TrademarkOppositionForm);
Vue.component("TrademarkOppositionList", TrademarkOppositionList);
Vue.component("TrademarkOppositionOwnerForm", TrademarkOppositionOwnerForm);
Vue.component("TrademarkOppositionOwnerList", TrademarkOppositionOwnerList);
Vue.component("TrademarkOppositionResponsibleForm",TrademarkOppositionResponsibleForm);
Vue.component("TrademarkOppositionResponsibleList",TrademarkOppositionResponsibleList);
Vue.component("TrademarkOppositionBasisForOppositionForm",TrademarkOppositionBasisForOppositionForm);
Vue.component("TrademarkOppositionBasisForOppositionList",TrademarkOppositionBasisForOppositionList);
Vue.component("TrademarkTransactionReport", TrademarkTransactionReport);
Vue.component("TrademarkTransactionReportDetail",TrademarkTransactionReportDetail);
Vue.component("NationalTrademarkForm", NationalTrademarkForm);
Vue.component("NationalTrademarkList", NationalTrademarkList);
Vue.component("NationalTrademarkHistoryForm", NationalTrademarkHistoryForm);
Vue.component("NationalTrademarkHistoryList", NationalTrademarkHistoryList);
Vue.component("NationalTrademarkNiceClassForm", NationalTrademarkNiceClassForm);
Vue.component("NationalTrademarkNiceClassList", NationalTrademarkNiceClassList);
Vue.component("NationalTrademarkOwnerForm", NationalTrademarkOwnerForm);
Vue.component("NationalTrademarkOwnerList", NationalTrademarkOwnerList);
Vue.component("NationalTrademarkRenewalsForm", NationalTrademarkRenewalsForm);
Vue.component("NationalTrademarkRenewalsList", NationalTrademarkRenewalsList);
Vue.component("NationalTrademarkResponsibleForm",NationalTrademarkResponsibleForm);
Vue.component("NationalTrademarkResponsibleList",NationalTrademarkResponsibleList);
Vue.component("NationalPatentForm", NationalPatentForm);
Vue.component("NationalPatentList", NationalPatentList);
Vue.component("NationalPatentOwnerList", NationalPatentOwnerList);
Vue.component("NationalPatentOwnerForm", NationalPatentOwnerForm);
Vue.component("NationalPatentResponsibleList", NationalPatentResponsibleList);
Vue.component("NationalPatentResponsibleForm", NationalPatentResponsibleForm);
Vue.component("NationalPatentInventorList", NationalPatentInventorList);
Vue.component("NationalPatentInventorForm", NationalPatentInventorForm);
Vue.component("NationalPatentAnnuityList", NationalPatentAnnuityList);
Vue.component("NationalPatentAnnuityForm", NationalPatentAnnuityForm);
Vue.component("NationalTrademarkCountryList", NationalTrademarkCountryList);
Vue.component("JobStatusList", JobStatusList);
Vue.component("JobStatusForm", JobStatusForm);
Vue.component("NationalTrademarkCountryForm", NationalTrademarkCountryForm);
Vue.component("NationalTrademarkCountryShowHistoryListForm",NationalTrademarkCountryShowHistoryListForm);
Vue.component("NationalTrademarkApplicationTypeForm",NationalTrademarkApplicationTypeForm);
Vue.component("NationalTrademarkApplicationTypeList",NationalTrademarkApplicationTypeList);
Vue.component("NationalPatentHistoryForm", NationalPatentHistoryForm);
Vue.component("NationalPatentHistoryList", NationalPatentHistoryList);
Vue.component("NationalPatentCountryList", NationalPatentCountryList);
Vue.component("NationalPatentCountryForm", NationalPatentCountryForm);
Vue.component("NationalPatentCountryShowHistoryListForm",NationalPatentCountryShowHistoryListForm);
Vue.component("EmploymentContractTypeForm", EmploymentContractTypeForm);
Vue.component("EmploymentContractTypeList", EmploymentContractTypeList);
Vue.component("EmploymentContractForm", EmploymentContractForm);
Vue.component("EmploymentContractList", EmploymentContractList);
Vue.component("EmployeeContractList", EmployeeContractList);
Vue.component("JobReminderCriterionForm", JobReminderCriterionForm);
Vue.component("JobReminderCriterionList", JobReminderCriterionList);
Vue.component("JobStatusRelationList", JobStatusRelationList);
Vue.component("JobStatusRelationForm", JobStatusRelationForm);
Vue.component("JobStatusRelationDetailList", JobStatusRelationDetailList);
Vue.component("JobStatusRelationDetailForm", JobStatusRelationDetailForm);
Vue.component("TimeTypeForm", TimeTypeForm);
Vue.component("TimeTypeList", TimeTypeList);
Vue.component("AnnualLeaveRightList", AnnualLeaveRightList);
Vue.component("AnnualLeaveRightForm", AnnualLeaveRightForm);
Vue.component("AnnualLeaveStatusList", AnnualLeaveStatusList);
Vue.component("AnnualLeaveStatusForm", AnnualLeaveStatusForm);
Vue.component("HourlyAnnualLeaveRequestForm", HourlyAnnualLeaveRequestForm);
Vue.component("HourlyAnnualLeaveEmployeeList", HourlyAnnualLeaveEmployeeList);
Vue.component("AnnualLeaveApproveForm", AnnualLeaveApproveForm);
Vue.component("AnnualLeaveEmployeeList", AnnualLeaveEmployeeList);
Vue.component("HourlyAnnualLeaveRequestApproveForm",HourlyAnnualLeaveRequestApproveForm);
Vue.component("HourlyAnnualLeaveRequestApproveList",HourlyAnnualLeaveRequestApproveList);
Vue.component("EmployeeHourlyAnnualLeaveRequestForm",EmployeeHourlyAnnualLeaveRequestForm);
Vue.component("EmployeeHourlyAnnualLeaveList", EmployeeHourlyAnnualLeaveList);
Vue.component("EmployeeAnnualLeaveRequestForm", EmployeeAnnualLeaveRequestForm);
Vue.component("EmployeeAnnualLeaveList", EmployeeAnnualLeaveList);
Vue.component("EmployeeAddressList", EmployeeAddressList);
Vue.component("EmployeeAddressForm", EmployeeAddressForm);
Vue.component("EmployeeEducationInformationList",EmployeeEducationInformationList);
Vue.component("EmployeeEducationInformationForm",EmployeeEducationInformationForm);
Vue.component("EmployeePowerOfAttorneyList", EmployeePowerOfAttorneyList);
Vue.component("EmployeePowerOfAttorneyForm", EmployeePowerOfAttorneyForm);
Vue.component("EmployeeContactForm", EmployeeContactForm);
Vue.component("EmployeeContactList", EmployeeContactList);
Vue.component("EmployeeLanguageForm", EmployeeLanguageForm);
Vue.component("EmployeeLanguageList", EmployeeLanguageList);
Vue.component("HourlyAnnualLeaveReportList", HourlyAnnualLeaveReportList);
Vue.component("ClientAccountingDifferentInvoiceReport",ClientAccountingDifferentInvoiceReport);
Vue.component("ClientAccountingInvoiceAlertDayReport",ClientAccountingInvoiceAlertDayReport);
Vue.component("ClientAccountingSpecialInvoiceReport",ClientAccountingSpecialInvoiceReport);
Vue.component("Dashboard", Dashboard);
Vue.component("MailToReadForm", MailToReadForm);
Vue.component("MailToReadList", MailToReadList);
Vue.component("NoteToBeOnInvoiceForm", NoteToBeOnInvoiceForm);
Vue.component("NoteToBeOnInvoiceList", NoteToBeOnInvoiceList);
Vue.component("InvoiceApprovalList", InvoiceApprovalList);
Vue.component("InvoiceList", InvoiceList);
Vue.component("InvoiceForm", InvoiceForm);
Vue.component("InvoicePdfForm", InvoicePdfForm);
Vue.component("InvoiceAddItemForm", InvoiceAddItemForm);
Vue.component("InvoiceAddDailyworkForm", InvoiceAddDailyworkForm);
Vue.component("InvoiceUpdateItemForm", InvoiceUpdateItemForm);
Vue.component("InvoiceDiscountForm", InvoiceDiscountForm);
Vue.component("TranslatorPaymentsList", TranslatorPaymentsList);
Vue.component("TranslatorPaymentsForm", TranslatorPaymentsForm);
Vue.component("ExchangeRateCalculatorForm", ExchangeRateCalculatorForm);
Vue.component("TotalImageUploadForm", TotalImageUploadForm);
Vue.component("InvoiceHeaderList", InvoiceHeaderList);
Vue.component("InvoiceHeaderForm", InvoiceHeaderForm);
Vue.component("AdminEmployeeHourlyAnnualLeaveList",AdminEmployeeHourlyAnnualLeaveList);
Vue.component("AdminEmployeeAnnualLeaveList", AdminEmployeeAnnualLeaveList);
Vue.component("TranslatorPaymentTranslationsList",TranslatorPaymentTranslationsList);
Vue.component("AdminAnnualLeaveApproveForm", AdminAnnualLeaveApproveForm);
Vue.component("EmployeeHourlyAdminAnnualLeaveRequestForm",EmployeeHourlyAdminAnnualLeaveRequestForm);
Vue.component("ServiceTypeInvoiceHeaderList", ServiceTypeInvoiceHeaderList);
Vue.component("ServiceTypeInvoiceHeaderForm", ServiceTypeInvoiceHeaderForm);
Vue.component("LedesList", LedesList);
Vue.component("LedesForm", LedesForm);
Vue.component("LedesDetailList", LedesDetailList);
Vue.component("LedesDetailForm", LedesDetailForm);
Vue.component("LedesCodeTypeList", LedesCodeTypeList);
Vue.component("LedesCodeTypeForm", LedesCodeTypeForm);
Vue.component("LedesTypeList", LedesTypeList);
Vue.component("LedesTypeForm", LedesTypeForm);
Vue.component("ReminderList", ReminderList);
Vue.component("ReminderReportList", ReminderReportList);
Vue.component("AnnualLeaveReportList", AnnualLeaveReportList);
Vue.component("OvertimeWorkTypeForm", OvertimeWorkTypeForm);
Vue.component("OvertimeWorkTypeList", OvertimeWorkTypeList);
Vue.component("OvertimeWorkReasonForm", OvertimeWorkReasonForm);
Vue.component("OvertimeWorkReasonList", OvertimeWorkReasonList);
Vue.component("OvertimeWorkList", OvertimeWorkList);
Vue.component("OvertimeWorkListForEmployee", OvertimeWorkListForEmployee);
Vue.component("OvertimeWorkListForManager", OvertimeWorkListForManager);
Vue.component("OvertimeWorkForm", OvertimeWorkForm);
Vue.component("OvertimeWorkApproveForm", OvertimeWorkApproveForm);
Vue.component("InvoiceStatusForm", InvoiceStatusForm);
Vue.component("InvoiceStatusList", InvoiceStatusList);
Vue.component("CreditCardForm", CreditCardForm);
Vue.component("CreditCardList", CreditCardList);
Vue.component("OfficialFeeForm", OfficialFeeForm);
Vue.component("OfficialFeeList", OfficialFeeList);
Vue.component("JobForm", JobForm);
Vue.component("JobList", JobList);
Vue.component("JobDetailForm", JobDetailForm);
Vue.component("JobNextForm", JobNextForm);
Vue.component("JobReportList", JobReportList);
Vue.component("JobHistoryList", JobHistoryList);
Vue.component("JobHistoryForm", JobHistoryForm);
Vue.component("DailyworkList", DailyworkList);
Vue.component("MultiDailyworkSaveForm", MultiDailyworkSaveForm);
Vue.component("DailyworkOfficialFeeForm", DailyworkOfficialFeeForm);
Vue.component("DailyworkReport", DailyworkReport);
Vue.component("BillingStatusForm", BillingStatusForm);
Vue.component("BillingStatusList", BillingStatusList);
Vue.component("ClientAccountingCategoryForm", ClientAccountingCategoryForm);
Vue.component("ClientAccountingCategoryList", ClientAccountingCategoryList);
Vue.component("InvoiceDiscountFormatForm", InvoiceDiscountFormatForm);
Vue.component("InvoiceDiscountFormatList", InvoiceDiscountFormatList);
Vue.component("PatentSearchDatabaseList", PatentSearchDatabaseList);
Vue.component("PatentSearchDatabaseForm", PatentSearchDatabaseForm);
Vue.component("BankForm", BankForm);
Vue.component("BankList", BankList);
Vue.component("PaymentForm", PaymentForm);
Vue.component("PaymentList", PaymentList);
Vue.component("PaymentDetailList", PaymentDetailList);
Vue.component("NationalTrademarkCountryNiceClassForm",NationalTrademarkCountryNiceClassForm);
Vue.component("NationalTrademarkCountryNiceClassList",NationalTrademarkCountryNiceClassList);
Vue.component("FindRecordForm", FindRecordForm);
Vue.component("DailyworkForm", DailyworkForm);
Vue.component("TrademarkObjectionToUsForm", TrademarkObjectionToUsForm);
Vue.component("TrademarkObjectionToUsList", TrademarkObjectionToUsList);
Vue.component("TrademarkObjectionToOpposingViewStatusForm",TrademarkObjectionToOpposingViewStatusForm);
Vue.component("TrademarkObjectionToOpposingViewStatusList",TrademarkObjectionToOpposingViewStatusList);
Vue.component("TrademarkObjectionToUsCaseDecisionForm",TrademarkObjectionToUsCaseDecisionForm);
Vue.component("TrademarkObjectionToUsCaseDecisionList",TrademarkObjectionToUsCaseDecisionList);
Vue.component("TrademarkObjectionToUsDecisionForm",TrademarkObjectionToUsDecisionForm);
Vue.component("TrademarkObjectionToUsDecisionList",TrademarkObjectionToUsDecisionList);
Vue.component("TrademarkObjectionToUsStatusForm",TrademarkObjectionToUsStatusForm);
Vue.component("TrademarkObjectionToUsStatusList",TrademarkObjectionToUsStatusList);
Vue.component("TrademarkObjectionToUsYidkStatusForm",TrademarkObjectionToUsYidkStatusForm);
Vue.component("TrademarkObjectionToUsYidkStatusList",TrademarkObjectionToUsYidkStatusList);
Vue.component("TrademarkProvisionalList", TrademarkProvisionalList);
Vue.component("TrademarkProvisionalForm", TrademarkProvisionalForm);
Vue.component("TrademarkProvisionalStatusForm", TrademarkProvisionalStatusForm);
Vue.component("TrademarkProvisionalStatusList", TrademarkProvisionalStatusList);
Vue.component("TrademarkProvisionalCaseInstructionList",TrademarkProvisionalCaseInstructionList);
Vue.component("TrademarkProvisionalCaseInstructionForm",TrademarkProvisionalCaseInstructionForm);
Vue.component("TrademarkBulletinCheckList", TrademarkBulletinCheckList);
Vue.component("TrademarkBulletinCheckForm", TrademarkBulletinCheckForm);
Vue.component("MailHistoryList", MailHistoryList);
Vue.component("MailHistoryForm", MailHistoryForm);
Vue.component("AgentTransactionsReportList", AgentTransactionsReportList);
Vue.component("AgentTransactionsReportTrademarkList",AgentTransactionsReportTrademarkList);
Vue.component("AgentTransactionsReportTrademarkOppositionList",AgentTransactionsReportTrademarkOppositionList);
Vue.component("AgentTransactionsReportNationalTrademarkList",AgentTransactionsReportNationalTrademarkList);
Vue.component("AgentTransactionsReportPatentList",AgentTransactionsReportPatentList);
Vue.component("AgentTransactionsReportDesignList",AgentTransactionsReportDesignList);
Vue.component("AgentTransactionsReportDesignOppositionList",AgentTransactionsReportDesignOppositionList);
Vue.component("AgentTransactionsReportDomainList",AgentTransactionsReportDomainList);
Vue.component("AgentTransactionsReportTrademarkProvisionalList",AgentTransactionsReportTrademarkProvisionalList);
Vue.component("AgentTransactionsReportTrademarkObjectionToUsList",AgentTransactionsReportTrademarkObjectionToUsList);
Vue.component("EInvoiceList", EInvoiceList);
Vue.component("EInvoiceForm", EInvoiceForm);
Vue.component("EInvoiceAwaitingApprovalList", EInvoiceAwaitingApprovalList);
Vue.component("EtebsList", EtebsList);
Vue.component("EtebsForm", EtebsForm);
Vue.component("PatentWipoXmlUploadForm", PatentWipoXmlUploadForm);
Vue.component("WipoXmlForm", WipoXmlForm);
Vue.component("UploadFromEtebsForm", UploadFromEtebsForm);
Vue.component("AccountingBooksList", AccountingBooksList);
Vue.component("AccountingBooksForm", AccountingBooksForm);
Vue.component("AccountingBooksAccountsList", AccountingBooksAccountsList);
Vue.component("AccountingBooksAccountsForm", AccountingBooksAccountsForm);
Vue.component("AccountingBooksReceiptList", AccountingBooksReceiptList);
Vue.component("AccountingBooksReceiptForm", AccountingBooksReceiptForm);
Vue.component( "AccountingBooksReceiptStandartList", AccountingBooksReceiptStandartList);
Vue.component("AccountingBooksReceiptStandartForm",AccountingBooksReceiptStandartForm);
Vue.component("StatementList", StatementList);
Vue.component("InvoiceAgingReportList", InvoiceAgingReportList);
Vue.component("TrademarkWipoXmlUploadForm", TrademarkWipoXmlUploadForm);
Vue.component("TrademarkWipoXmlForm", TrademarkWipoXmlForm);
Vue.component("OpenJobListForm", OpenJobListForm);
Vue.component("NationalTrademarkTransactionReportList",NationalTrademarkTransactionReportList);
Vue.component("NationalTrademarkTransactionReportDetail",NationalTrademarkTransactionReportDetail);
Vue.component("TrademarkOppositionStatusList", TrademarkOppositionStatusList);
Vue.component("TrademarkOppositionStatusForm", TrademarkOppositionStatusForm);
Vue.component("InvoiceEditNoteForm", InvoiceEditNoteForm);
Vue.component("NationalTrademarkSearchStatusForm",NationalTrademarkSearchStatusForm);
Vue.component("NationalTrademarkSearchStatusList", NationalTrademarkSearchStatusList);
Vue.component("ClientFolderList", ClientFolderList);
Vue.component("TrademarkDetailForm", TrademarkDetailForm);
Vue.component("ClientInvoiceReportList", ClientInvoiceReportList);
Vue.component("ClientInvoiceReportListCallForm",ClientInvoiceReportListCallForm);
Vue.component("JobUpdateNoteForm", JobUpdateNoteForm);
Vue.component("BusinessPartnerTypeList", BusinessPartnerTypeList);
Vue.component("BusinessPartnerTypeForm", BusinessPartnerTypeForm);
Vue.component("BusinessPartnerResponsibleList", BusinessPartnerResponsibleList);
Vue.component("BusinessPartnerResponsibleForm", BusinessPartnerResponsibleForm);
Vue.component("BusinessPartnerAddressList", BusinessPartnerAddressList);
Vue.component("BusinessPartnerAddressForm", BusinessPartnerAddressForm);
Vue.component("BusinessPartnerContactInformationList",BusinessPartnerContactInformationList);
Vue.component("BusinessPartnerContactInformationForm",BusinessPartnerContactInformationForm);
Vue.component("BusinessPartnerList", BusinessPartnerList);
Vue.component("BusinessPartnerForm", BusinessPartnerForm);
Vue.component("AccountingTransactionTypeForm", AccountingTransactionTypeForm);
Vue.component("AccountingTransactionTypeList", AccountingTransactionTypeList);
Vue.component("AccountingTransactionDoesntBalancedEntitiesReportList",AccountingTransactionDoesntBalancedEntitiesReportList);
Vue.component("InvoiceAddCautionForm", InvoiceAddCautionForm);
Vue.component("SearchTrademarkForCopyForm", SearchTrademarkForCopyForm);
Vue.component("XmlOwnerClientSearchListCallForm",XmlOwnerClientSearchListCallForm);
Vue.component("XmlOwnerClientSearchList",XmlOwnerClientSearchList);
Vue.component("AgentTransactionsReportTrademarkSearchList",AgentTransactionsReportTrademarkSearchList);
Vue.component("AccountingTransactionLedgerReportList",AccountingTransactionLedgerReportList);
Vue.component("AccountingTransactionZeroSequenceNoReportList",AccountingTransactionZeroSequenceNoReportList);
Vue.component("AnnuityDetailStatusContentModalForm", AnnuityDetailStatusContentModalForm);

import "vueditor/dist/style/vueditor.min.css";
let config = {
  toolbar: [
    "removeFormat",
    "undo",
    "redo",
    "|",
    "element",
    "fontName",
    "fontSize",
    "foreColor",
    "backColor",
    "divider",
    "bold",
    "italic",
    "underline",
    "strikeThrough",
    "link",
    "unLink",
    "divider",
    "subscript",
    "superscript",
    "divider",
    "justifyLeft",
    "justifyCenter",
    "justifyRight",
    "justifyFull",
    "|",
    "indent",
    "outdent",
    "insertOrderedList",
    "insertUnorderedList",
    "|",
    "picture",
    "table",
    "|",
    "code",
    "fullscreen",
    "sourceCode",
    "markdown",
  ],
  fontName: [
    { val: "arial black" },
    { val: "times new roman" },
    { val: "Courier New" },
  ],
  fontSize: [
    "12px",
    "14px",
    "16px",
    "18px",
    "20px",
    "24px",
    "28px",
    "32px",
    "36px",
  ],
  uploadUrl: "",
  id: "",
  classList: [],
};
Vue.use(Vueditor, config);

//flatpickr language
if (sessionStorage.languageValue == "tr") {
  flatpickr.localize(Turkish);
}
// flatpickr.localize(languageForFlatPickr);

//directives
Vue.directive("b-tooltip", VBTooltip);
Vue.directive("b-popover", VBPopover);
Vue.directive("mask", AwesomeMask);
//use
Vue.use(VueSweetalert2);

import "@core/scss/vue/libs/vue-good-table.scss";
import "@core/scss/vue/libs/vue-context.scss";
import "@core/scss/vue/libs/vue-sweetalert.scss";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import router from "./router";
import App from "./App.vue";

//Manuel Created Components

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";

import app from "./store/app";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VueExcelXlsx);
Vue.use(store);

// Composition API
Vue.use(VueCompositionAPI);
Vue.use(CustomPlugin);
Vue.use(FTPClientPlugin);
Vue.use(excel);

// import core styles
require("@core/scss/core.scss");

Vue.mixin(togglePasswordVisibility);

// import assets styles
require("@/assets/scss/style.scss");

//diğer dillerin eklenmesi için core/utils/validations/validations.js içinden dil eklenmesi gerekiyor.
localize("tr");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  silentTranslationWarn: true,
  render: (h) => h(App),
}).$mount("#app");
