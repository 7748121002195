<template>
    <div>
      <annuity-form :getAnnuityList="getAnnuityList" ref="annuityFormModal"></annuity-form>
      <annuity-detail-list-for-invoice :getAnnuityList="getAnnuityList" ref="annuityDetailListForInvoiceModal"></annuity-detail-list-for-invoice>
      <annuity-detail-status-content-modal-form :getAnnuityList="getAnnuityList" ref="annuityDetailStatusContentModal"></annuity-detail-status-content-modal-form>
      <b-row>
          <b-col class="col-12">
              <div style="float:left;" class="custom-search d-flex justify-content-end">
                <b-col class="col-md-10">
                    <b-form-select v-model="statusSelect" size="sm" value-field="id" text-field="name" :options="statusOption" />
                </b-col>
              </div>
              <div style="float:right;" class="custom-search d-flex justify-content-end">
                <b-form-group>
                  <div class="d-flex align-items-center">
                    <b-button size="sm" variant="outline-primary" class="mr-05" @click="getAnnuityList()"  v-on:keyup.enter="getAnnuityList()">{{ $t('others.list') }}</b-button>                                    
                    <b-button style="float:right;" class="mr-05" size="sm" variant="outline-primary" @click="getInfo(0)">{{ $t('annuity.add_agent_annuity') }}</b-button>                                     
                    <b-button style="float:right;" size="sm" variant="outline-primary" @click="exportExcel()">{{ $t('excel.excel') }}</b-button>                   </div>
                </b-form-group>
              </div>
          </b-col>
      </b-row>
      <!-- table -->
      <vue-good-table
          ref="annuityTable"
          :columns="columns"
          :rows="rows"
          styleClass="vgt-table condensed tableSize" 
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,}"
          :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
          :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
          theme="my-theme"
      >
        <div slot="emptystate">
           {{ $t('dataTable.data_not_found') }}
        </div>
        <!-- Row: Table Row -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.field ==='listAgentName'"
            class="text-nowrap"
          >
            {{ $t('annuity.lis_agent_name') }}
          </span>
          <span
            v-else-if="props.column.field ==='folderName'"
            class="text-nowrap"
          >
            {{ $t('annuity.folder_name') }}
          </span>
          <span
            v-else-if="props.column.field ==='annuityDetailCount'"
            class="text-nowrap"
          >
            {{ $t('annuity.annuity_detail_count') }}
          </span>
          <span
            v-else-if="props.column.field ==='annuityDetailCountWhoDoesntHavePatentId'"
            class="text-nowrap"
          >
            {{ $t('annuity.mismatched_records') }}
          </span>
          <span
          v-else-if="props.column.field ==='annuityDetailCountWhoDoesntHavePaymentId'"
          class="text-nowrap"
        >
          {{ $t('annuity.no_receipt') }}
        </span>
          <span
            v-else-if="props.column.field ==='status'"
            class="text-nowrap"
          >
            {{ $t('annuity.status') }}
          </span>
          <span
            v-else-if="props.column.field ==='process'"
            class="text-nowrap"
          >
            {{ $t('others.process') }}
          </span>
        </template>
        <!-- Column: Table Column -->
        <template
            slot="table-row"
            slot-scope="props"
        >
          <!-- Column: Process -->
          <span v-if="props.column.field === 'process'">
            <span>
              <b-button v-b-tooltip.html :title=" $t('others.edit')"  v-b-modal.modal-center variant="primary" class="btn-icon mb-1 ml-1" size="sm" @click="getInfo(props.row.id)" >  
                <feather-icon icon="EditIcon"/>
              </b-button>    
              <b-button v-if="props.row.annuityStatusId > 1" v-b-tooltip.html :title="$t('others.download')" v-b-modal.modal-center variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="downloadPdfFile(props.row.id)">
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <b-button v-if="props.row.annuityStatusId == 2" v-b-tooltip.html :title="$t('annuity.invoice')" v-b-modal.modal-center variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="AnnuityDetailForInvoiceInfo(props.row.id)">
                <feather-icon icon="ListIcon" />
              </b-button>
              <b-button v-if="props.row.annuityStatusId > 1" v-b-tooltip.html :title="$t('annuity.status_content')" v-b-modal.modal-center variant="success" class="btn-icon mb-1 ml-1" size="sm" @click="annuityDetailStatusContent(props.row.id)">
                <feather-icon icon="SlidersIcon" />
              </b-button>
              <b-button v-if="props.row.canDelete == false" v-b-tooltip.html :title=" $t('others.delete')" variant="warning" class="btn-icon mb-1 ml-1" size="sm" @click="deleted(props.row)">
                <feather-icon icon="XIcon" />
              </b-button>
            </span>
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{ $t('dataTable.total') }} {{ props.total }} {{ $t('dataTable.record') }} </span>
              <b-form-select v-model="pageLength" :options="['10','50','100', '1000']" class="mx-1" @input="(value)=>props.perPageChanged({currentPerPage:value})"/>
              <span class="text-nowrap"> {{ $t('dataTable.row_is_show') }} </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0" @input="(value)=>props.pageChanged({currentPage:value})">
                <template #prev-text> 
                  <feather-icon icon="ChevronLeftIcon" size="18" />  
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        statusSelect: 1,
        statusOption: [{
          id: 0,
          name: this.$t('translate.select')
        },
        {
          id: 1,
          name: this.$t('annuity.active')
        },
        {
          id: 2,
          name: this.$t('annuity.passive')
        },
        ],
        pageLength: 10,
        dir: false,
        columns: [
          {
            label : this.$t('annuity.lis_agent_name'),
            field : 'agent',
          },
          {
            label: this.$t('annuity.folder_name'),
            field: `fileName`,
          },
          {
            label: this.$t('annuity.annuity_detail_count'),
            field: `annuityDetailCount`,
          },
          {
            label : this.$t('annuity.mismatched_records'),
            field : 'annuityDetailCountWhoDoesntHavePatentId',
          },
          {
            label : this.$t('annuity.no_receipt'),
            field : 'annuityDetailCountWhoDoesntHavePaymentId',
          },
          {
            label : this.$t('annuity.status'),
            field : 'annuityStatus',
          },
          {
            label: 'Process',
            field: 'process',
            width: '15em'
          },
        ],
        rows: [],
        searchTerm: '',
      }
    },
    mounted(){
      this.getAnnuityList()
    },
    methods: {
      async deleted(data) {
        data = {id: data.id}
        const controller = {name : 'Annuity'}
        const payload = {data: data, controller: controller}
       this.$DeleteConfirm().then(result => {
         if(result.value){
            this.$store.dispatch('moduleAuth/delete',payload).then((response) => {
              if(response.data.resultStatus == true){
                this.$DeleteAlert()
                this.getAnnuityList()
              } else {
                this.$WarningAlert(response.data.resultMessage)
                this.getAnnuityList()
              }     
            })
         }
       })  
      },
      async getInfo(id){
        this.$refs.annuityFormModal.showInfo(id)
      },
      async AnnuityDetailForInvoiceInfo(id){
        this.$refs.annuityDetailListForInvoiceModal.showInfo(id)
      },
      async annuityDetailStatusContent(id){
        this.$refs.annuityDetailStatusContentModal.showInfo(id)
      },
      getAnnuityList() {
            this.rows = []
            const controller = { name: 'Annuity'}
            const payload = { controller: controller}
            this.$store.dispatch('moduleAuth/getList', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    response.data.annuityList.forEach(element => {
                        this.rows.push(element)
                    })
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        },
        downloadPdfFile(id) {
            const data = {
                id: id
            }
            const controller = {
                name: 'Annuity',
                actionName: 'DownloadAnnutyReport'
            }
            const payload = {
                data: data,
                controller: controller
            }

            let loader = this.$loading.show()
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("Our reporting letters and the payment receipts", response.data.base64, 'pdf')
                } else {
                    this.$WarningAlert(response.data.resultMessage)
                }
                loader.hide() 
            })            
        },
      exportExcel() {
            const controller = { name: 'Annuity', actionName: 'CanExport'}            
            const data = { columnList : this.columns, DataList : this.rows}
            const payload = { data : data, controller: controller}
            this.$store.dispatch('moduleAuth/customService', payload).then((response) => {
                if (response.data.resultStatus == true) {
                    this.$downloadFile("DokumanTipleri", response.data.base64, 'xlsx')
                }
                 else {
                    this.$WarningAlert(response.data.resultMessage)
                }
            })
        }
    },
  }
  </script>
  <style lang="scss" >
  .excel-css{
    float: right;
    margin-right: 1em;
    width: 9.5em;
    color: #7367f0;
    border: 1px solid #7367f0;
    background-color: transparent;
    margin-left: 1em;
    height: 2em;
    border-radius: 0.358rem; 
  }
  .tableSize {
    font-size: 13px!important;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
  }
  </style>
  